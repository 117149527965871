
import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, } from '@mui/material';
import { firestore } from '../firebase/firebase';
import ExpansionCard from '../components/expansionCard';
import moment from 'moment/moment';

function DashboardContent() {
    const matches = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = React.useState(true);
    const [expansions, setExpansions] = React.useState(sessionStorage.getItem('expansions') ? JSON.parse(sessionStorage.getItem('expansions')).sort((a, b) => {
      return a.year - b.year;
  }):[]);


    //useEffect for media query
    React.useEffect(() => {
  
    } , [matches])
    React.useEffect(() => {
      if(!sessionStorage.getItem('expansions')){
        setLoading(true)
        firestore.collection('expansions').orderBy('year', 'asc').get()
        .then(querySnapshot => {
          const response = querySnapshot.docs.map((doc) => {
            return {
              ...doc.data(),
                id: doc.id  
            }
          })
          setExpansions(response)
          sessionStorage.setItem('expansions', JSON.stringify(response));
          setLoading(false)
          
        }).catch (e => {
                
                setLoading(false);
            }
        )


          } else {
          setLoading(false)
          }
  
    } , [])

  return (
    
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              '#075d8e',
            flexGrow: 1,
            width: '100%',
            marginTop:"15%",
          }}
        ><Typography variant="h4" component="h1" gutterBottom>EXPANSIONS</Typography>
          <Container maxWidth="lg" sx={{ p: 4 }}>
            <Divider/>
                <Grid container spacing={2} >
                    {!loading && expansions
                    //sort by date using moment
                    .sort((b, a) => {
                        return moment(b.year, 'MMMM Do YYYY').diff(moment(a.year, 'MMMM Do YYYY'))
                    })
                    

                    .map((expansion, index) => {

                    return (
                        <Grid item key={index} xs={6} md={5} lg={4} >
                            <ExpansionCard expansionData={expansion}/>
                                                
                        </Grid>
                    )
                    })}
                </Grid>
          </Container>
        </Box>

  );
}

export default function Expansions() {
  return <DashboardContent />;
}