import * as React from 'react';
import { firestore, removeFile, uploadFile } from '../../firebase/firebase';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
//import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExpansionForm from '../components/ExpansionForm';

import _, { cloneDeep } from 'lodash'
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const CreateExpansion = (props) => {

    const matches = useMediaQuery('(max-width:600px)');
    const [saving, setSaving] = React.useState(false);
    const [expansionData, setExpansionData] = React.useState({});
    const [saveState, setSaveState] = React.useState({});
    const [boosterPackImage] = React.useState('https://firebasestorage.googleapis.com/v0/b/kfkbattaliondatabase.appspot.com/o/boxes%2Fboosterpack1.png?alt=media&token=71f1b3dd-f24a-43ed-a38f-c9ffa5590486')
    const [initialExpansionData] = React.useState(
        {
            name: "",
            year: new Date().getFullYear(),
            boxImage: "",
            desc: "",
        }
    );

    const updateData = React.useCallback((e) => {
        setExpansionData(e)
    },
        [],
    );

    const navigate = useNavigate()

    //useEffect for media query
    React.useEffect(() => {
  
    } , [matches])

    const uploadPhoto = async (photo) => {
        let path = 'boxes/' + expansionData.name + '.png';
        let query = await uploadFile(path, photo);
        return query.url;
    }

    const removePhoto = async (photoURL) => {
        let query = await removeFile(photoURL);
        return query.fileDeleted;
    }

    const saveData = async () => {
        setSaving(true);
        //Remove header photo
        if (saveState.boxImage !== expansionData.boxImage)
            await removePhoto(saveState.boxImage);

        //Add new header photo
        expansionData.boxImage = expansionData.boxImage instanceof File
            ? await uploadPhoto(expansionData.boxImage)
            : expansionData.boxImage


        expansionData.id = uuidv4();
        //save to firestore
        await firestore.collection('expansions').doc(expansionData.id).set(expansionData);
        //add expansionData to sessionStorage expansions
        let expansions = JSON.parse(sessionStorage.getItem('expansions'));
        expansions.push(expansionData);
        sessionStorage.setItem('expansions', JSON.stringify(expansions));
        setSaveState(_.cloneDeep(expansionData));
        setSaving(false)
        //timeout to allow the database to update
        setTimeout(() => {
            navigate('/expansions/' + expansionData.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,''));
        }, 3000)
        
      
    };

    const cancelSave = () => {
        setExpansionData(_.cloneDeep(saveState));
    };

    React.useEffect(() => {
        setExpansionData(cloneDeep(initialExpansionData));
        setSaveState(cloneDeep(initialExpansionData));
    }, [initialExpansionData])


    return (
    <React.Fragment>
        
        <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            '#075d8e',
          margin: "10%",
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Container maxWidth="xl" sx={{ p: 4 }}>

        <ExpansionForm expansionData={initialExpansionData} updateData={updateData} cancelSave={cancelSave} saveState={saveState} saveData={saveData}
        saving={saving} boosterpack={boosterPackImage}/> 
        </Container>
        </Box>
        
        </React.Fragment>
    );
}
export default CreateExpansion;