import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Divider, Grid } from '@mui/material';
import FIELD from '../img/kfkfield.png';

import CHARACTER from '../img/charactercard.png';
import EQUIPMENT from '../img/equipmentcard.png';
import SACRIFICE from '../img/sacrificecard.png';
import VIRUS from '../img/viruscard.png';
import LOCATION from '../img/locationcard.png';
import GOD from '../img/godcard.png';
import GALAXY from '../img/galaxycard.png';
import RITUAL from '../img/ritualcard.png';
import useMediaQuery from '@mui/material/useMediaQuery';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function RuleBook() {
    const matches = useMediaQuery('(max-width:600px)');
  const [value, setValue] = React.useState( sessionStorage.getItem('rulebook') ? JSON.parse(sessionStorage.getItem('rulebook')) : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem('rulebook', newValue);
  };

  React.useEffect(() => {
    
    } , [value, matches])

  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: '#023857', display: 'flex',borderRadius:'10px', boxShadow: '0px 0px 10px #023857' }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider', backgroundColor:'#36a1df', minWidth: matches? '100px' : '200px', maxWidth: matches? '100px' : '200px', borderTopLeftRadius:'10px', borderBottomLeftRadius:'10px', 
    boxShadow: '10px 0px 2px rgba(0, 0, 0, 0.5)', }}
      >
        <Tab sx={{color:value === 0 ? 'black':'white', backgroundColor: value === 0 ?'#383838':'#36a1df'}} label="Game Modes" {...a11yProps(0)} />
        <Tab sx={{color:value === 1 ? 'black':'white', backgroundColor: value === 1 ?'#383838':'#36a1df'}} label="Draw Phase" {...a11yProps(1)} />
        <Tab sx={{color:value === 2 ? 'black':'white', backgroundColor: value === 2 ?'#383838':'#36a1df'}} label="Main Phase" {...a11yProps(2)} />
        <Tab sx={{color:value === 3 ? 'black':'white', backgroundColor: value === 3 ?'#383838':'#36a1df'}} label="Battle Phase" {...a11yProps(3)} />
        <Tab sx={{color:value === 4 ? 'black':'white', backgroundColor: value === 4 ?'#383838':'#36a1df'}} label="End Phase" {...a11yProps(4)} />
        <Tab sx={{color:value === 5 ? 'black':'white', backgroundColor: value === 5 ?'#383838':'#36a1df'}} label="Combat" {...a11yProps(5)} />
        <Tab sx={{color:value === 6 ? 'black':'white', backgroundColor: value === 6 ?'#383838':'#36a1df'}} label="Playing Field" {...a11yProps(6)} />
        <Divider sx={{backgroundColor:'white', width:'90%', margin:'30px'}}/>
        <Tab sx={{color:value === 8 ? 'black':'red', fontWeight:'bold', backgroundColor: value === 8 ?'#383838':'#36a1df'}} label="Character Cards" {...a11yProps(7)} />
        <Tab sx={{color:value === 9 ? 'black':'green', fontWeight:'bold', backgroundColor: value === 9 ?'#383838':'#36a1df'}} label="Equipment Cards" {...a11yProps(8)} />
        <Tab sx={{color:value === 10 ? 'black':'blue', fontWeight:'bold', backgroundColor: value === 10 ?'#383838':'#36a1df'}} label="Sacrifice Cards" {...a11yProps(9)} />
        <Tab sx={{color:value === 11 ? 'black':'white', fontWeight:'bold', backgroundColor: value === 11 ?'#383838':'#36a1df'}} label="Location Cards" {...a11yProps(10)} />
        <Tab sx={{color:value === 12 ? 'black':'purple', fontWeight:'bold', backgroundColor: value === 12 ?'#383838':'#36a1df'}} label="Virus Cards" {...a11yProps(11)} />
        <Tab sx={{color:value === 13 ? 'black':'pink', fontWeight:'bold', backgroundColor: value === 13 ?'#383838':'#36a1df'}} label="Galaxy Cards" {...a11yProps(12)} />
        <Tab sx={{color:value === 14 ? 'black':'black', fontWeight:'bold', backgroundColor: value === 14 ?'#383838':'#36a1df'}} label="God Cards" {...a11yProps(13)} />
        <Tab sx={{color:value === 15 ? 'black':'blue', fontWeight:'bold', marginBottom:'100px', backgroundColor: value === 15 ?'#383838':'#36a1df'}} label="Ritual Cards" {...a11yProps(14)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Typography variant={matches ? 'body1' : 'h5'}>Game Modes</Typography>
        <Divider style={{backgroundColor:'white', marginBottom:'20px'}}/>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography style={{fontWeight:'bold'}}>Original</Typography>
                <Grid container spacing={2} alignContent="center">
                    <Grid item xs={12}>
                        <Typography variant={'body1'} >
                            The original mode of KFK Battalion. The goal is to defeat all players by destroying their deck. 
                            When a player's field has no character type cards, their hand can be attacked directly, automatically destroying those cards.
                        </Typography>
                    </Grid>
                </Grid>
               
            </Grid>
            <Grid item xs={12}>
                <Typography style={{fontWeight:'bold'}}>Life Points</Typography>
                <Grid container spacing={2} alignContent="center">
                    <Grid item xs={12}>
                        <Typography variant={'body1'} >
                            Players start with 100 Life Points. When a player's field has no character type cards, their Life Points can be attacked directly.
                            Destroying a Character type card will reduce the player's Life Points by calculating the difference of The Destroyed Card's ATK minus The Attacking card's ATK.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1} sx={{width:'100%'}}>
        <Typography variant={'h5'} sx={{width:'100%'}}>Draw Phase</Typography>
            <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
            <Typography variant={'body1'} style={{width:'100%'}} >
                At the start of each turn, the Player draws cards from the top of their deck until they have 5 cards in their hand.
            
        </Typography>
            
                
                
                        
               
             
               
           
        
      </TabPanel>
      <TabPanel value={value} index={2}>
      <Typography variant={'h5'} sx={{width:'100%'}}>Main Phase</Typography>
            <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
            <Typography variant={'body1'} style={{width:'100%'}} >
                During a Player's Main Phase, they can play any number of cards from their hand provided they have enough slots on their field. 
                Activate Type Equipment cards and certain Character Abilities may be activated during this phase.
                <br/><br/>
                Players may look through their hand or Extra Deck (if applicable)
            
        </Typography>
            
                
                
                        
               
             
               
           
      </TabPanel>
      <TabPanel value={value} index={3}>
      <Typography variant={'h5'} sx={{width:'100%'}}>Battle Phase</Typography>
            <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
            <Typography variant={'body1'} style={{width:'100%'}} >
                During a Player's Battle Phase, they can attack an opposing card with 1 of their own cards on the field. Players do not have to attack if they don't want to.
            
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={4}>
      <Typography variant={'h5'} sx={{width:'100%'}}>End Phase</Typography>
            <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
            <Typography variant={'body1'} style={{width:'100%'}} >
                During a Player's End Phase, they may activate any unused Equipment cards on the field or in their hand. Any Cards with turn limits are resolved based on how many turns it's been.
            
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={5}>
      <Typography variant={'h5'} sx={{width:'100%'}}>Combat</Typography>
            <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
            <Typography variant={'body1'} style={{width:'100%'}} >
                There are a few outcomes when a Card declares an attack on another Card.
                <br/><br/>
                <ul>
                    <li>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} > If the Attacker has higher ATK power. </Typography>
                        <Typography variant={'body2'}  > The Defender takes the ATK power as damage to its DEF, DEF in this case acting as it's Health Points. </Typography>
                    </li>
                    <br/>
                    <li>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} > If the Attacker has lower ATK power. </Typography>
                        <Typography variant={'body2'} > The Defender takes half of the ATK power as damage to its DEF, DEF in this case acting as it's Health Points. </Typography>
                        <Typography variant={'body2'} > The Attacker also takes half of the ATK power as damage to its DEF, DEF in this case acting as it's Health Points. </Typography>
                    </li>
                    <br/>
                    <li>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} > If the Attacker has equal ATK power. </Typography>
                        <Typography variant={'body2'} > The Defender takes half of the ATK power as damage to its DEF, DEF in this case acting as it's Health Points. </Typography>
                    </li>
                </ul>
            
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Typography variant={'h5'} sx={{width:'100%'}}>The Playing Field</Typography>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <img src={FIELD} alt="field" style={{width:'100%'}}/>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <Typography variant={'body1'} style={{width:'100%'}} >
                The Playing Field has by default, 3 Slots for Character Type Cards and 3 Slots for Equipment Cards, as well as a space for the Main Deck, and a discard pile/graveyard.
                <br/><br/>
                There are cards and effects that enable a 4th Slot for the field, but these 4th slots are disabled by default.
                <br/><br/>
                Location Cards are played universally on the field, so only 1 may be played at a time during a game between all players.
        </Typography>
            
      </TabPanel>
      <TabPanel value={value} index={8}>
        <Typography variant={'h5'} sx={{width:'100%'}}>Character Cards</Typography>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <img src={CHARACTER} alt="field" style={{width:'40%'}}/>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ol>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Card Name </Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'} > Character Franchise/Logo </Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> ATK Power </Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> DEF Power/Health Points </Typography>
                    </li>
                    <li style={{marginLeft:'0px', fontSize:"0px"}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold', fontSize:'0px'}} ><small>We don't fuck with the number 5</small></Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Card Description </Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Character Ability </Typography>
                        <Divider/>
                        <Typography variant={'subtitle1'}  > Some characters have unique abilities that can be passive or active. </Typography>
                    </li>
                    
                </ol>
            </Grid>
        </Grid>     
      </TabPanel>
      <TabPanel value={value} index={9}>
        <Typography variant={'h5'} sx={{width:'100%'}}>Equipment Cards</Typography>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <img src={EQUIPMENT} alt="field" style={{width:'40%'}}/>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ol>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'} > Card Name </Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Card Description</Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Equipment Ability </Typography>
                    </li>
                    
                </ol>
            </Grid>
            
        </Grid> 
        <Divider/>
          <Typography variant={'subtitle1'} align={'left'} > Equipment Cards come in a few different types that may or may not be displayed on the 
          actual card because the distinction of these types was made at a later date. All types can be played Face Down initially to surprise your opponents.
          <br/><br/>
          <b>Equip</b> - Equipment that can be equipped to a character.
            <br/><br/>
            <b>Activate</b> - Equipment that can be activated to do something.
            <br/><br/>
            <b>Quickplay</b> - Equipment that can be activated during someone elses turn in response to an action.
            <br/><br/>
            <b>Continuous</b> - Equipment that can be activated and stays active on the field.
            <br/><br/>
            <b>Ritual</b> - Equipment that is used to Ritual Summon a Ritual Card.
          </Typography>    
      </TabPanel>
      <TabPanel value={value} index={10}>
        <Typography variant={'h5'} sx={{width:'100%'}}>Sacrifice Cards</Typography>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <img src={SACRIFICE} alt="field" style={{width:'40%'}}/>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ol>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'} > Card Name </Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> ATK Power</Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> DEF Power/Health Points </Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Card Description </Typography>
                    </li>
                    <li style={{marginLeft:'0px', fontSize:"0px"}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold', fontSize:'0px'}} ><small>We don't fuck with the number 5</small></Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Sacrifice Level </Typography>
                    </li>
                    
                </ol>
            </Grid>
            
        </Grid> 
        <Divider/>
          <Typography variant={'subtitle1'}  > Sacrifice Cards are Character Type Cards that require a sacrifice of other Character Type Cards that are in play, to summon. <br/>This amount is reflected by their Sacrifice Level. There are some Level 4 Sacrifices too.

          </Typography>    
      </TabPanel>
      <TabPanel value={value} index={11}>
        <Typography variant={'h5'} sx={{width:'100%'}}>Location Cards</Typography>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <img src={LOCATION} alt="field" style={{width:'40%'}}/>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ol>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'} > Card Name </Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Turn Lock</Typography>
                        <Divider/>
                        <Typography variant={'body1'} align={'left'}> - The amount of turns needed to pass before a player can change out Locations</Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Universal Location Effect </Typography>
                        <Divider/>
                        <Typography variant={'body1'} align={'left'}> - This ability affects all cards.</Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Buff </Typography>
                        <Divider/>
                        <Typography variant={'body1'} align={'left'}> - The Franchise and Buff that franchise gets.</Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Debuff </Typography>
                        <Divider/>
                        <Typography variant={'body1'} align={'left'}> - The Franchise and Debuff that franchise gets.</Typography>
                    </li>
                </ol>
            </Grid>
        </Grid> 
      </TabPanel>
      <TabPanel value={value} index={12} >
        <Typography variant={'h5'} sx={{width:'100%'}}>Virus Cards</Typography>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <img src={VIRUS} alt="field" style={{width:'40%'}}/>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ol>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'} > Card Name </Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Virus Type</Typography>
                        <Divider/>
                        <Typography variant={'body1'} align={'left'}> - The type of Virus</Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Turns till Auto-Cure </Typography>
                        <Divider/>
                        <Typography variant={'body1'} align={'left'}> - The amount of turns until this Virus automatically cures itself.</Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Virus Effect </Typography>
                        <Divider/>
                        <Typography variant={'body1'} align={'left'}> - The effect of the Virus when it's attached to a Character Type card.</Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Type Chart </Typography>
                        <Divider/>
                        <Typography variant={'body1'} align={'left'}> - This chart shows which Viruses can beat.</Typography>
                    </li>
                </ol>
            </Grid>
        </Grid> 
        <Divider/>
          <Typography variant={'subtitle1'}  >  <br/>
          Virus Cards come in 3 different types:
            <ul>    
                <li>
                    <Typography variant={'body1'} align={'left'}> Phi Virus Θ </Typography>
                </li>
                <li>
                    <Typography variant={'body1'} align={'left'}> Sigma Virus Σ </Typography>
                </li>
                <li>
                    <Typography variant={'body1'} align={'left'}> Omega Virus Ω </Typography>
                </li>
            </ul>
            When a card is infected by a Virus, the only way to cure them is to either wait out the turn counter or try and use a Virus of advantageous type.<br/>
            This can be done by following the Type Chart on the cards. When you attempt to cure a Virus Card using this method, you flip a coin;
            <ul>    
                <li>
                    <Typography variant={'body1'} align={'left'}> Heads: The Virus is Cured and both Virus Cards used are destroyed. </Typography>
                </li>
                <li>
                    <Typography variant={'body1'} align={'left'}> Tails: The Virus is Cured and Destroyed, but the Virus you used to cure it infects the card now.</Typography>
                </li>
            </ul>


          </Typography>
          
      </TabPanel>
      <TabPanel value={value} index={13}>
        <Typography variant={'h5'} sx={{width:'100%'}}>Galaxy Cards</Typography>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <img src={GALAXY} alt="field" style={{width:'40%'}}/>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ol>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'} > Card Name </Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Materials Required to Summon</Typography>
                        <Divider/>
                        <Typography variant={'body1'} align={'left'}> - You need this number of Character Cards in this Franchise/Logo to Galaxy Summon this card. They are used as Materials</Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Galaxy Ability </Typography>
                        <Divider/>
                        <Typography variant={'body1'} align={'left'}> - This is the Galaxy Card's unique ability</Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> ATK Power </Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> DEF Power/Health Points </Typography>
                    </li>
                </ol>
            </Grid>
        </Grid> 
        <Divider/>
          <Typography variant={'subtitle1'}  >  <br/>
          Galaxy Cards are another Character Type card that requires sacrificing cards from specific Franchises to summon. This is called a Galaxy Summon.
           The sacrificed cards are attached to the Galaxy Card as Materials which are then used in various ways relating to their Galaxy Ability.


          </Typography>
      </TabPanel>
      <TabPanel value={value} index={14}>
        <Typography variant={'h5'} sx={{width:'100%'}}>God Cards</Typography>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <img src={GOD} alt="field" style={{width:'40%'}}/>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ol>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'} > Card Name </Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> ATK Power</Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> DEF Power/Health Points </Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Summon Method </Typography>
                        <Divider/>
                        <Typography variant={'body1'} align={'left'}> - This is the method on summoning this God Card</Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> God Ability </Typography>
                        <Divider/>
                        <Typography variant={'body1'} align={'left'}> - This card's unique God Ability.</Typography>
                    </li>
                </ol>
            </Grid>
        </Grid> 
        <Divider/>
          <Typography variant={'subtitle1'}  >  <br/>
          God Cards are a special Character Type card that requires a hefty tribute to summon. This is called a God Summon.
          These cards can be special summoned in other ways such as with certain Equipment Cards or Card Abilities, but if these cards are summoned improperly they must be destroyed the following turn.


          </Typography>
      </TabPanel>
      <TabPanel value={value} index={15}>
        <Typography variant={'h5'} sx={{width:'100%'}}>Ritual Cards</Typography>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <img src={RITUAL} alt="field" style={{width:'40%'}}/>
        <Divider style={{backgroundColor:'white', marginBottom:'20px', width:'100%'}}/>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ol>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'} > Card Name </Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> ATK Power</Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> DEF Power/Health Points </Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Summon Method </Typography>
                        <Divider/>
                        <Typography variant={'body1'} align={'left'}> - This is the method on Ritual Summoning this card.</Typography>
                    </li>
                    <li style={{marginLeft:'0px'}}>
                        <Typography variant={'body1'} sx={{fontWeight:'bold'}} align={'left'}> Ritual Ability </Typography>
                        <Divider/>
                        <Typography variant={'body1'} align={'left'}> - This card's unique Ritual Ability.</Typography>
                    </li>
                </ol>
            </Grid>
        </Grid> 
        <Divider/>
          <Typography variant={'subtitle1'}  >  <br/>
            Ritual Cards are another Character Type card that requires a unique summoning method involving specific cards. This is called a Ritual Summon.
            Ritual Card's abilities are only able to be used if the Ritual Card was properly Ritual Summoned, meaning Special Summoning them is only great for using their ATK Power.
            <br/><br/>
            (And in this game, abilities are vital to winning)

          </Typography>
      </TabPanel>
    </Box>
  );
}