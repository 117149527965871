import React from "react";
import Grid from '@mui/material/Grid';
import { Button, Divider, Collapse, IconButton } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useLocation } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import sprSLEEVE    from '../img/sprite-sleeve.png'
import Img from "react-cool-img";

const NavBar = () => {

  const matches = useMediaQuery('(max-width:1000px)');
  const navigate = useNavigate();
  const location = useLocation(); 
  const [open, setOpen] = React.useState(false);
  // useEffect for media query
  React.useEffect(() => {

  }, [matches])

  React.useEffect(() => {
    setOpen(false); // Close the menu when the location changes
  }, [location]);

  const smallStyle = matches ? {
    color: 'white',
    fontSize: '10px',
    fontWeight: 'bold',
  } : {
    color: 'white',
  }

  return (


      <div className="NavBar">
        {matches ?
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <IconButton onClick={() => setOpen(!open)} style={{ color: 'white' }}>{open?<MenuOpenIcon/>:<MenuIcon />}</IconButton>
            </Grid>
            <Grid item xs={12}>
              <Collapse timeout={'auto'} direction={'horizontal'} in={open}>
                <Grid container spacing={1} style={{ display: 'flex', flexDirection: 'column', position: 'fixed', left: '0px', top: '56px', backgroundColor: '#00324f',
                 padding: '10px', width: '50vw', height: '100vh', zIndex: '999',
                 boxShadow: '2px' }}>
                  <Button style={location.pathname === '/' ? { ...smallStyle, color: 'black', border:'1px solid #36a1df', margin:'1px' } : smallStyle} onClick={() => {navigate('/'); setOpen(false);}}><b>Home</b></Button>
                  <Divider style={{ backgroundColor: 'white' }} />
                  <Button startIcon={<Img src={sprSLEEVE}/>} style={location.pathname.includes('/cards') ? { ...smallStyle, color: 'black', border:'1px solid #36a1df', margin:'1px' } : smallStyle} onClick={() => {navigate('/cards'); setOpen(false);}}><b>Cards</b></Button>
                  <Divider style={{ backgroundColor: 'white' }} />
                  <Button style={location.pathname === '/rules' ? { ...smallStyle, color: 'black', border:'1px solid #36a1df', margin:'1px' } : smallStyle} onClick={() => {navigate('/rules'); setOpen(false);}}><b>Rules</b></Button>
                  <Divider style={{ backgroundColor: 'white' }} />
                  <Button style={location.pathname.includes('/expansions') ? { ...smallStyle, color: 'black', border:'1px solid #36a1df', margin:'1px' } : smallStyle} onClick={() => {navigate('/expansions'); setOpen(false);}}><b>Expansions</b></Button>
                  <Divider style={{ backgroundColor: 'white' }} />
                  <Button style={location.pathname.includes('/franchises') ? { ...smallStyle, color: 'black', border:'1px solid #36a1df', margin:'1px' } : smallStyle} onClick={() => {navigate('/franchises'); setOpen(false);}}><b>Franchises</b></Button>
                  <Divider style={{ backgroundColor: 'white' }} />
                  <Button style={location.pathname === '/upcoming' ? { ...smallStyle, color: 'black', border:'1px solid #36a1df', margin:'1px' } : smallStyle} onClick={() => {navigate('/upcoming'); setOpen(false);}}><b>Upcoming Cards</b></Button>
                  <Divider style={{ backgroundColor: 'white' }} />
                  {/*<Button style={location.pathname === '/doku' ? { ...smallStyle, color: 'black', border:'1px solid #36a1df', margin:'1px' } : smallStyle} onClick={() => {navigate('/doku'); setOpen(false);}}><b>KFKDoku</b></Button>
                  <Divider style={{ backgroundColor: 'white' }} />*/}
                </Grid>
              </Collapse>
            </Grid>
          </Grid>

          :

          <Grid container spacing={1}>
            <Button style={location.pathname === '/' ? { ...smallStyle, color: '#00324f', border:'1px solid #00324f', margin:'1px' } : smallStyle} onClick={() => navigate('/')}><b>Home</b></Button>
            <Button startIcon={<Img src={sprSLEEVE}/>} style={location.pathname.includes('/cards') ? { ...smallStyle, color: '#00324f', border:'1px solid #00324f', margin:'1px' } : smallStyle} onClick={() => navigate('/cards')}><b>Cards</b></Button>
            <Button style={location.pathname.includes('/rules') ? { ...smallStyle, color: '#00324f', border:'1px solid #00324f', margin:'1px' } : smallStyle} onClick={() => navigate('/rules')}><b>Rules</b></Button>
            <Button style={location.pathname.includes('/expansions') ? { ...smallStyle, color: '#00324f', border:'1px solid #00324f', margin:'1px' } : smallStyle} onClick={() => navigate('/expansions')}><b>Expansions</b></Button>
            <Button style={location.pathname.includes('/franchises')? { ...smallStyle, color: '#00324f', border:'1px solid #00324f', margin:'1px' } : smallStyle} onClick={() => navigate('/franchises')}><b>Franchises</b></Button>
            <Button style={location.pathname === '/upcoming' ? { ...smallStyle, color: '#00324f', border:'1px solid #00324f', margin:'1px' } : smallStyle} onClick={() => navigate('/upcoming')}><b>Upcoming Cards</b></Button>
            {/*<Button style={location.pathname === '/doku' ? { ...smallStyle, color: '#00324f', border:'1px solid #00324f', margin:'1px' } : smallStyle} onClick={() => navigate('/doku')}><b>KFKDoku</b></Button>*/}
          </Grid>}
        
      
        
      </div>
  );
};

export default NavBar;
