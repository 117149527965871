import React from 'react';
import { Divider, Paper, Typography } from '@mui/material';

const Page404 = (props) => {
    return <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Paper style={{ maxWidth: '500px', width: '100%', padding: '16px', margin: '32px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <Typography variant='h4' style={{ marginBottm: '16px' }}>Sorry! :(</Typography>

            </div>
            <Divider />
            <Divider />
            <Typography variant='body1' style={{ marginTop: '16px' }}><strong>404. Page not found</strong></Typography>
            <Typography variant='body1'>The page you are looking for could not be found.</Typography>
            <Divider />
            <Divider />
            <Typography variant='body1'>Something went wrong or maybe you mistyped? Either way you're in the void now.</Typography>

        </Paper>
    </div>
}

export default Page404;