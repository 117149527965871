import React from "react";
import Grid from '@mui/material/Grid';
import { Button } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import {  useNavigate } from "react-router-dom";

const AdminBar = () => {

    const matches = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();

    //useEffect for media query
    React.useEffect(() => {
  
    } , [matches])
  
    const smallStyle = matches ? {
      color:'white',
      fontSize:'10px',
      fontWeight:'bold'
    } : {
      color:'white',
    }

  

  return (
    <div>
        
      <div className="NavBar">
        <small>
        <Grid container spacing={2} style={{backgroundColor:'black', padding:'5px', borderRadius:'10px', marginTop:'5px'}}>

            <Button onClick={() => {navigate('/admin/createCard')}} size="small" style={smallStyle}><b>Card Management</b></Button>
            |
            <Button onClick={() => {navigate('/admin/createExpansion')}} size="small" style={smallStyle}><b>Expansion Management</b></Button>

            
        </Grid>
        </small>
      </div>
    </div>
  );
};

export default AdminBar;