
import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import CharacterTable from '../components/cardTable';
import Img from 'react-cool-img';
import { useCards } from '../providers/cardProvider';

import sprCHARACTER from '../img/sprite-character.png'
import sprEQUIPMENT from '../img/sprite-equipment.png'
import sprSACRIFICE from '../img/sprite-sacrifice.png'
import sprLOCATION  from '../img/sprite-location.png'
import sprVIRUS     from '../img/sprite-virus.png'
import sprGALAXY    from '../img/sprite-galaxy.png'
import sprGOD       from '../img/sprite-god.png'
import sprRITUAL    from '../img/sprite-ritual.png'





function DashboardContent() {
    const matches = useMediaQuery('(max-width:600px)');
    const { loading, character } = useCards();
    const [selection, setSelection] = React.useState(sessionStorage.getItem('cardsType') ? JSON.parse(sessionStorage.getItem('cardsType')):0);

    //useEffect for media query
    React.useEffect(() => {
        
    } , [matches, selection, loading, character])

    function getLabel(selection) {
      switch(selection){
        case 0: return 'characters';
        case 1: return 'equipments';
        case 2: return 'sacrifices';
        case 3: return 'locations';
        case 4: return 'viruses';
        case 5: return 'galaxies';
        case 6: return 'gods';
        case 7: return 'rituals';
        default: return 'characters';
      }
    }

    function getList(selection) {
      switch(selection){
        case 0: return 'character';
        case 1: return 'equipment';
        case 2: return 'sacrifice';
        case 3: return 'locations';
        case 4: return 'virus';
        case 5: return 'galaxy';
        case 6: return 'god';
        case 7: return 'ritual';
        default: return 'character';
      }
    }

    function getCardType(selection) {
      switch(selection) {
        case 0: return 'character';
        case 1: return 'equipment';
        case 3: return 'location';
        case 4: return 'virus';
        case 7: return 'ritual';
        case 2: return 'sacrifice';
        case 6: return 'god';
        case 5: return 'galaxy';
        default: return 'character';
      }
    }
  
    const smallStyleRed = matches ? {
        color:'white',
        backgroundColor:'#ff1100',
        fontSize:'10px',
        fontWeight:'bold',
        margin:'5px'
      } : {
        color:selection === 0 ?'#ff1100':'white',
        border: selection === 0 ?'1px solid #ff1100':'',
        backgroundColor:selection === 0 ?'black':'#ff1100',
        margin:'10px'
      }
    const smallStyleGreen = matches ? {
    color:'white',
    backgroundColor:'#00b029',
    fontSize:'10px',
    fontWeight:'bold',
    margin:'5px'
    } : {
      color:selection === 1 ?'#00b029':'white',
      border: selection === 1 ?'1px solid #00b029':'',
      backgroundColor:selection === 1 ?'black':'#00b029',
      margin:'10px'
    }
    const smallStyleBlue = matches ? {
      color:'white',
      backgroundColor:'#003cff',
      fontSize:'10px',
      fontWeight:'bold',
      margin:'5px'
      } : {
        color:selection === 2 ?'#1966ff':'white',
        border: selection === 2 ?'1px solid #1966ff':'',
        backgroundColor:selection === 2 ?'black':'#003cff',
        margin:'10px'
      }
      const smallStyleLocation = matches ? {
        color:'white',
        backgroundColor:'#a3a3a3',
        fontSize:'10px',
        fontWeight:'bold',
        margin:'5px'
        } : {
          color:selection === 3 ?'#a3a3a3':'white',
          border: selection === 3 ?'1px solid #a3a3a3':'',
          backgroundColor:selection === 3 ?'black':'#a3a3a3',
          margin:'10px'
        }
    const smallStylePurple = matches ? {
      color:'white',
      backgroundColor:'#4c00ff',
      fontSize:'10px',
      fontWeight:'bold',
      margin:'5px'
      } : {
        color:selection === 4 ?'#5319ff':'white',
        border: selection === 4 ?'1px solid #5319ff':'',
        backgroundColor:selection === 4 ?'black':'#4c00ff',
        margin:'10px'
      }
      const smallStyleGalaxy = matches ? {
        color:'white',
        backgroundColor:'#583bff',
        fontSize:'10px',
        fontWeight:'bold',
        margin:'5px'
        } : {
          color:selection === 5 ?'#7c40ff':'white',
          border: selection === 5 ?'1px solid #7c40ff':'',
          backgroundColor:selection === 5 ?'black':'#583bff',
          margin:'10px'
        }
    const smallStyleBlack = matches ? {
      color:'white',
      backgroundColor:'#333333',
      fontSize:'10px',
      fontWeight:'bold',
      margin:'5px'
      } : {
        color:selection === 6 ?'#474747':'white',
        border: selection === 6 ?'1px solid #474747':'',
        backgroundColor:selection === 6 ?'black':'#333333',
        margin:'10px'
      }
    const smallStyleRitual = matches ? {
      color:'white',
      backgroundColor:'#25367a',
      fontSize:'10px',
      fontWeight:'bold',
      margin:'5px'
      } : {
        color:selection === 7 ?'#3d6eff':'white',
        border: selection === 7 ?'1px solid #3d6eff':'',
        backgroundColor:selection === 7 ?'black':'#25367a',
        margin:'10px'
      }


  return (
    
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
            '#075d8e',
          margin: matches?"30%":"10%",
          width: '100%',
          overflow: 'hidden',
          }}
        >
          <Container maxWidth="lg" sx={{ p: 0 }}>
            
            <Grid container spacing={0}
            direction="row"
            alignItems="center"
            justifyContent="center" 
            >

                <Button size={matches?'small':'medium'} startIcon={matches?null:<Img src={sprCHARACTER}/>} style={smallStyleRed} variant={'contained'}
                 onClick={() => {setSelection(0); sessionStorage.setItem('cardsType', JSON.stringify(0))}}>{matches?<Img src={sprCHARACTER}/>:<b>Character</b>}</Button>
                
                <Button size={matches?'small':'medium'} startIcon={matches?null:<Img src={sprEQUIPMENT}/>} style={smallStyleGreen} variant={'contained'}
                 onClick={() => {setSelection(1); sessionStorage.setItem('cardsType', JSON.stringify(1))}}>{matches?<Img src={sprEQUIPMENT}/>:<b>Equipment</b>}</Button>
                
                <Button size={matches?'small':'medium'} startIcon={matches?null:<Img src={sprSACRIFICE}/>} style={smallStyleBlue} variant={'contained'}
                 onClick={() => {setSelection(2); sessionStorage.setItem('cardsType', JSON.stringify(2))}}>{matches?<Img src={sprSACRIFICE}/>:<b>Sacrifice</b>}</Button>
                
                <Button size={matches?'small':'medium'} startIcon={matches?null:<Img src={sprLOCATION}/>} style={smallStyleLocation} variant={'contained'}
                 onClick={() => {setSelection(3); sessionStorage.setItem('cardsType', JSON.stringify(3))}}>{matches?<Img src={sprLOCATION}/>:<b>Location</b>}</Button>
                
                <Button size={matches?'small':'medium'} startIcon={matches?null:<Img src={sprVIRUS}/>} style={smallStylePurple} variant={'contained'}
                  onClick={() => {setSelection(4); sessionStorage.setItem('cardsType', JSON.stringify(4))}}>{matches?<Img src={sprVIRUS}/>:<b>Virus</b>}</Button>
                
                <Button size={matches?'small':'medium'} startIcon={matches?null:<Img src={sprGALAXY}/>} style={smallStyleGalaxy} variant={'contained'}
                  onClick={() => {setSelection(5); sessionStorage.setItem('cardsType', JSON.stringify(5))}}>{matches?<Img src={sprGALAXY}/>:<b>Galaxy</b>}</Button>
                
                <Button size={matches?'small':'medium'} startIcon={matches?null:<Img src={sprGOD}/>} style={smallStyleBlack} variant={'contained'}
                  onClick={() => {setSelection(6); sessionStorage.setItem('cardsType', JSON.stringify(6))}}>{matches?<Img src={sprGOD}/>:<b>God</b>}</Button>
                
                <Button size={matches?'small':'medium'} startIcon={matches?null:<Img src={sprRITUAL}/>} style={smallStyleRitual} variant={'contained'}
                  onClick={() => {setSelection(7); sessionStorage.setItem('cardsType', JSON.stringify(7))}}>{matches?<Img src={sprRITUAL}/>:<b>Ritual</b>}</Button>
      
            
         

            </Grid>
            <Divider/>
                <Grid container spacing={3} >
                  <Grid item xs={12} md={12} lg={12}>
                    <CharacterTable cardType={getLabel(selection)} cardSet={getCardType(selection)} listType={getList(selection)}/> 
                  </Grid>
                </Grid>
          </Container>
        </Box>

  );
}

export default function Cards() {
  return <DashboardContent />;
}