import React from "react";
import {  Card, CardContent, CardMedia, Typography } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useNavigate } from 'react-router-dom';

const ExpansionCard = (props) => {
    const navigate = useNavigate();
    const [flipped, setFlipped] = React.useState(false);

    const matches = useMediaQuery('(max-width:600px)');
    //useEffect for media query
    React.useEffect(() => {

    } , [matches])

  return (
    <div >{flipped ? 
        <Card onMouseLeave={() => setFlipped(false)}   onClick={() => {
            navigate('/expansions/'+props.expansionData.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,''), { state: { expansion: props.expansionData},
            });

        }}      style={matches? {width:'150px', minHeight:'200px', maxHeight:'300px', padding:'5%', alignItems: "center", backgroundColor:'black', color:'white'}:
        {width:'350px', minHeight:'400px', maxHeight:'400px', padding:'5%', alignItems: "center", backgroundColor:'black', color:'white',cursor:'pointer'}}><CardContent style={{ position: 'relative' }}>
        <CardMedia
          image={props.expansionData.boxImage}
          title={props.expansionData.name}
          style={{
            height: matches ? '100px' : '250px',
            width: matches ? '100px' : '300px',
            position: 'absolute',
            top: matches ? '50%' : '25%',
            left: matches ? '50%' : '10%',
            opacity: '10%' ,
            transform: matches ? 'translate(-50%, -50%)' : 'none',
          }}
        />
        <Typography variant={matches ? 'body1' : 'h5'} style={matches?{fontSize:'12px'}:{}}>{props.expansionData.name}</Typography>
                <Typography variant={'subtitle1'} style={matches?{fontSize:'10px'}:{}}>Printed in {props.expansionData.year}</Typography>
        <Typography variant={'body1'} style={matches ? { fontSize: '10px' } : {}}>
          {props.expansionData.desc}
        </Typography>
        
      </CardContent>
        </Card>
        :
        <Card onMouseEnter={() => setFlipped(true)} onMouseLeave={() => setFlipped(!flipped)}  onClick={() => {
            navigate('/expansions/'+props.expansionData.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,''), { state: { expansion: props.expansionData} });

        }}
        style={matches? {width:'150px', minHeight:'200px', maxHeight:'200px', padding:'5%', alignItems: "center", backgroundColor:'black', color:'white'}:
        {width:'350px', minHeight:'400px', maxHeight:'400px', padding:'5%', alignItems: "center", backgroundColor:'black', color:'white',cursor:'pointer'}}>
            {props.expansionData.boxImage ? <CardMedia 
                image={props.expansionData.boxImage}
                title={props.expansionData.name}
                style={matches?{height:'100px', width:'100px', marginLeft:'15%', marginTop:'15%'} :{height:'250px', width:'300px', marginLeft:'10px'}}
            /> : <CardContent style={{marginBottom:'20%', marginTop:'30%'}}><Typography variant={'h5'}><HelpOutlineIcon/></Typography><Typography variant={'subtitle1'}>Image Not Found</Typography></CardContent>}
            <CardContent>
                <Typography variant={matches ? 'body1' : 'h5'} style={matches?{fontSize:'12px'}:{}}>{props.expansionData.name}</Typography>
                <Typography variant={'subtitle1'} style={matches?{fontSize:'10px'}:{}}>{props.expansionData.year}</Typography>
            </CardContent>
        </Card>}
    </div>
  );
};

export default ExpansionCard;