import React from "react";
import { Button, Divider, Box, Container, Typography, Grid, Paper, IconButton } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";
import Img from 'react-cool-img'
//import { useLocation } from "react-router-dom";
import ICON from '../img/icon.png';
import Sleeve from '../img/Sleeve.png'
//import Skeleton from '@mui/material/Skeleton';
import { useCards } from "../providers/cardProvider";
import bleed from '../img/bleed.png'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const Artist = () => {

    const matches = useMediaQuery('(max-width:1000px)');
    const { cards, character, equipment, sacrifice, locations, virus, galaxy, god, ritual } = useCards();
    const [loading, setLoading] = React.useState(true);
    const [cardData, setCardData] = React.useState([]);

    const [currentPage, setCurrentPage] = React.useState(1);
    const [turningPage, setTurningPage] = React.useState(false);
    const cardsPerPage = 12; // Number of cards to display per page

    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = cardData.slice(indexOfFirstCard, indexOfLastCard);
    const totalPages = Math.ceil(cardData.length / cardsPerPage);

    const nextPage = () => {
        setTurningPage(true);
        if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
        }
        setTimeout(() => {
          setTurningPage(false);
        }, 800);
      };
    
      const prevPage = () => {
        setTurningPage(true);
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
        setTimeout(() => {
            setTurningPage(false);
          }, 800);
      };

    const navigate = useNavigate();
    //const location = useLocation()

    let url = window.location.href;
    //split the url by the / character
    let path = url.split('/')[4];
    path = path.replace(/%20/g, ' ');
    //url is /artists/:name
    //get name from url
    //

    

    React.useEffect(() => {
          setLoading(true);
          setTurningPage(true);
          if(!sessionStorage.getItem('cards'))
            {
                const filteredCards = [].concat(...cards.map(category => category.cards.filter(card => card.artist === path)));
                setCardData(filteredCards);

                setLoading(false);
                setTurningPage(false)
            } else {
          
                const filteredCards = [].concat(...JSON.parse(sessionStorage.getItem('cards')).map(category => category.cards.filter(card => card.artist === path)));
                setCardData(filteredCards);
    
                setLoading(false);
                setTurningPage(false)
            }
    } , [])

    React.useEffect(() => {
    } , [matches])

    function getCardList(cardType) {
        switch(cardType) {
          case 'character': return character;
          case 'equipment': return equipment;
          case 'location': return locations;
          case 'virus': return virus;
          case 'ritual': return ritual;
          case 'sacrifice': return sacrifice;
          case 'god': return god;
          case 'galaxy': return galaxy;
          default: return [];
        }
      }
  
    const getCardIndex = (id, name, type) => {
        let cardList = []
        cardList = getCardList(type).filter(card=> card.name.toLowerCase().replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === name)
        let index = cardList.findIndex((item) => {
          return item.id === id;
        })
    
        return index;
      }
  
    const goToCharacter = (id, name, type) => {
        navigate(`/cards/${type}/${name}-${id}`, { state: { id: name},
      });
    }
  
    return (
      <div>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                '#075d8e',
              flexGrow: 1,
              width: '100vw',
              marginTop:matches?"30%":"15%",
            }}
          >
          <Container maxWidth="lg" sx={{ p: 4 }}>
            <Grid container spacing={1}>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={() => navigate(-1)}>Back</Button>
                </Grid>
            </Grid>
            
            <Divider style={{margin:'10px'}} />
  
            <Grid container spacing={1}>
                <Paper style={{              
                    borderRadius: '10px',
              width: '100%',
              padding: '10px',
              margin: '10px',
              backgroundColor: '#36a1df',
              backgroundImage: `url(${ICON})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              position: 'relative',
              height: '100%',
              minHeight: '1200px',
            }}>
                    <Grid container spacing={2}>
                        <Grid container item xs={12}>
                            <Grid item xs={6}>
                                <Typography style={{color:'white'}} variant="h4">{path}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{color:'white'}} variant="h4">{cardData.length} Cards</Typography>
                            </Grid>
                           
                        </Grid>
                        <Grid item xs={12}>
                            <Divider style={{backgroundColor:'white'}}/>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={4}>
                                <IconButton onClick={()=> prevPage()}
                                style={{backgroundColor:'#075d8e', color:'white'}}><ArrowCircleLeftIcon/></IconButton>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography style={{color:'white'}} variant="h5">Page {currentPage} of {totalPages}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <IconButton onClick={()=> nextPage()}
                                style={{backgroundColor:'#075d8e', color:'white'}}><ArrowCircleRightIcon/></IconButton>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} style={{ paddingBottom: '50px' }}>
                        {
                            !loading && currentCards.map((card, index) => {
                                return (
                                    <Grid key={index} item xs={3} >
                                    <Img  onClick={ ()=> goToCharacter(getCardIndex(card.id, card.name.toLowerCase().replace(/\s/g, '-')
                                    .replace(/#/g, '')
                                    .replace(/[.:'"]/g, ''), card.type), card.name.toLowerCase().replace(/\s/g, '-')
                                    .replace(/#/g, '')
                                    .replace(/[.:'"]/g, ''), card.type)}
                                    placeholder={Sleeve} src={card.cardImage} alt={card.name} style={matches ? {width:'100px',
                                    maskImage: `url(${bleed})`,
                                    maskSize: '100px auto',
                                    maskRepeat: 'no-repeat',
                                    maskPosition: 'center',
                                    WebkitMaskImage: `url(${bleed})`,
                                    WebkitMaskSize: '100px auto',
                                  }: {width:'200px', cursor:'pointer',
                                    
                                    maskImage: `url(${bleed})`,
                                    maskSize: '200px auto',
                                    maskRepeat: 'no-repeat',
                                    maskPosition: 'center',
                                    WebkitMaskImage: `url(${bleed})`,
                                    WebkitMaskSize: '200px auto',
                                    }}/>
                                </Grid>
                                )
                            })
                        }
                        </Grid>
                        <Grid item xs={12}>
                            <Divider style={{backgroundColor:'white'}}/>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={4}>
                                <IconButton onClick={()=> prevPage()}
                                style={{backgroundColor:'#075d8e', color:'white'}}><ArrowCircleLeftIcon/></IconButton>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography style={{color:'white'}} variant="h5">Page {currentPage} of {totalPages}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <IconButton onClick={()=> nextPage()}
                                style={{backgroundColor:'#075d8e', color:'white'}}><ArrowCircleRightIcon/></IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid> 

            
                  
          </Container>
        </Box>
      </div>
    );
  };
  
  export default Artist;
