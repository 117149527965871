import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { Button, Box, Container } from '@mui/material';

import { useAuth } from '../providers/authProvider';

const Login = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const { login } = useAuth();

    async function handleSignIn(event, email, password) {
        try {
            setError("")
            await login(email, password);
        }
        catch {
            setError("Incorrect email/password");
        }
    };

    const onChangeHandler = (event) => {
        const { name, value } = event.currentTarget;

        if (name === 'userEmail') {
            setEmail(value);
        }
        else if (name === 'userPassword') {
            setPassword(value);
        }
    };

    return (
        // <Aux>
        <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            '#075d8e',
          flexGrow: 1,
          marginTop:"10%",
        }}
      >
        <Container maxWidth="lg" sx={{ m: 4 }}>
            
            <form noValidate autoComplete="off">
               
                    <TextField
                        type="email"
                        name="userEmail"
                        value={email}
                        id="userEmail"
                        onChange={(event) => onChangeHandler(event)}
                        onKeyPress={(event) => event.key === "Enter" && handleSignIn("", email, password)}
                        label="Email"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                    <br />
                    <TextField
                        color="primary"
                        type="password"
                        name="userPassword"
                        value={password}
                        id="userPassword"
                        onChange={(event) => onChangeHandler(event)}
                        onKeyPress={(event) => event.key === "Enter" && handleSignIn("", email, password)}
                        label="Password"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                    <br />
             
                <Button variant="contained" color="primary" onClick={(event) => { handleSignIn(event, email, password) }}>Sign In</Button>
                <br />

                {error !== null && <div >{error}</div>}


            </form>
        </Container >
      </Box>
    );
};

export default Login;