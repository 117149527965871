import React from "react";
import { Button, Divider, Box, Container, Typography, Grid, Paper } from "@mui/material";
import Marquee from "react-fast-marquee";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";
import Img from 'react-cool-img'
import { firestore } from "../firebase/firebase";
import { useLocation } from "react-router-dom";
import Sparkles from "../components/Sparkles";
import Sleeve from '../img/Sleeve.png'
//import Skeleton from '@mui/material/Skeleton';
import { useCards } from "../providers/cardProvider";
import bleed from '../img/bleed.png'

const Franchise = (props) => {

  const matches = useMediaQuery('(max-width:1000px)');
  const { character } = useCards();
  const [franchiseData, setFranchiseData] = React.useState({});
  const [cards, setCards] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  const location = useLocation()
  let path = new URLSearchParams(location.search).get("name");
  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }
  React.useEffect(() => {
  
        setLoading(true);
        let franchiseResponse = []
        let response = [];
        firestore.collection('logos').where('name', '==', path).get()
        .then(querySnapshot => {
          franchiseResponse = querySnapshot.docs.map((doc) => {
            return {
              ...doc.data(),
                id: doc.id  
            }
            
          })
       
        
        }).then(() => {
          if(!sessionStorage.getItem('characters'))
          {

            setFranchiseData(character.filter(card => card.Franchise === franchiseResponse[0].name && card.origin.length > 0));
            setCards(response.sort((a, b) => (a.name > b.name) ? 1 : -1));
            setLoading(false);
            
          } else {
            response = JSON.parse(sessionStorage.getItem('characters')).filter(card => card.Franchise === franchiseResponse[0].name && card.origin.length > 0)
            //order by name
            response.sort((a, b) => (a.name > b.name) ? 1 : -1)
            setFranchiseData(franchiseResponse[0]);
            setCards(response);
            setLoading(false);
          }
        }).catch (e => {
          setLoading(false);
        })


  } , [])
  React.useEffect(() => {
  

} , [matches])


  //get index of card in list us
  const getCardIndex = (id, name) => {
    let cardList = []
    cardList = character.filter(card=> card.name.toLowerCase().replace(/\s/g, '-').replace(/#/g, '').replace(/[.:']/g, '') === name.toLowerCase().replace(/\s/g, '-').replace(/[.:']/g, ''))
    let index = cardList.findIndex((item) => {
      return item.id === id;
    })

    return index;
  }


  const goToCharacter = (id, name) => {
      navigate(`/cards/character/${name.toLowerCase().replace(/\s/g, '-').replace(/#/g, '').replace(/[.:']/g, '')}-${id}`, { state: { id: name},
    });
  }

  return (
    <div>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              '#075d8e',
            flexGrow: 1,
            width: '100vw',
            marginTop:matches?"30%":"15%",
          }}
        >
        <Container maxWidth="lg" sx={{ p: 4 }}>
          <Grid container spacing={1}>
              <Grid item>
                  <Button variant="contained" color="primary" onClick={() => navigate(-1)}>Back</Button>
              </Grid>
          </Grid>
          
          <Divider style={{margin:'10px'}} />

          <Grid container spacing={1}>
              <Paper style={{backgroundColor:'#101721', borderRadius:'10px', width:'100%', padding:'10px'}}>
                  <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Img src={franchiseData.image} alt={franchiseData.name} style={matches ? {width:'150px', margin:'0px'}:{width:'500px', margin:'0px'}}/>
                      </Grid>
                      <Grid item xs={6} sm={6}  md={6} lg={6} xl={6}>
                          <Typography variant={matches ? "body1" :"h4"} component="h1" style={{color:'white'}}>{franchiseData.name}</Typography>
                          <Typography variant="body1" component="h1" style={matches ?{color:'white', fontSize:'8px'}:{color:'white'}}><b>Created by {franchiseData.author}</b></Typography>
                          <Divider style={{backgroundColor:'white',}}/>
                          <Typography variant="body1" component="h1" style={matches ?{color:'white', fontSize:'8px', marginBottom:'40px'}:{color:'white', marginBottom:'40px'}}><b>{cards.length} Characters</b></Typography>
                          <Typography variant="body1" component="h5" style={matches?{color:'white',fontSize:'10px'}:{color:'white'}}>{franchiseData.desc}</Typography>
                      </Grid>
                    
                      <Grid item xs={12} sm={12}  md={12} lg={12} xl={12}>
                       
                        <Marquee gradient={false}
                        speed={matches?20:20} pauseOnHover={true} 
                        style={{ marginBottom:'30px', overflow:'hidden'}}>
                          {!loading && shuffle(cards).map((card, index) => {
                              return ( card.foil || card.cloudyfoil ? 
                              <Sparkles key={index}>
                                  <Img onClick={() => goToCharacter(getCardIndex(card.id, card.name), card.name)} placeholder={Sleeve} src={card.cardImage} alt={card.name} 
                                  style={{width:matches?'100px':'150px', margin:'0px', zIndex:'1',cursor:'pointer', boxShadow: '0px 0px 20px rgba(251, 255, 0,1)',
                                  maskImage: `url(${bleed})`,
                                      maskSize: matches?'100px auto':'150px auto',
                                      maskRepeat: 'no-repeat',
                                      maskPosition: 'center',
                                      WebkitMaskImage: `url(${bleed})`,
                                      WebkitMaskSize: matches?'100px auto':'150px auto',}} />
                                  </Sparkles> :
                            <Img onClick={() => goToCharacter(getCardIndex(card.id, card.name), card.name)} key={index}
                            placeholder={Sleeve} src={card.cardImage} alt={card.name} style={{width:matches?'100px':'150px', zIndex:'1',cursor:'pointer',
                            maskImage: `url(${bleed})`,
                                    maskSize: matches?'100px auto':'150px auto',
                                    maskRepeat: 'no-repeat',
                                    maskPosition: 'center',
                                    WebkitMaskImage: `url(${bleed})`,
                                    WebkitMaskSize: matches?'100px auto':'150px auto',}} />
                          )})}
                        </Marquee>
                     
                      </Grid>
                     
                  </Grid>
              </Paper>


          </Grid>       
        </Container>
      </Box>
    </div>
  );
};

export default Franchise;