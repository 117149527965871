import React from 'react'
import Helmet from 'react-helmet'

const locales = {
  "en": "en_US"
}

const Meta = (data) => {
  const lang = data.lang || "en"
  const site_name = data.site_name || "KFK Battalion Database"
  const title = data.title || 'KFK Battalion Database'
  const description = data.description || "DO the impossible, SEE the invisible, ROW ROW FIGHT THE POWER"
  const image = data.image
  const type = "website"
  const width = data.image && (data.width || 1200)
  const height = data.image && (data.height || 630)
  const color = data.color || "#36a1df"

  return (
    <Helmet>
      <html lang={ lang } />
      <title>{ title }</title>
      <meta name="description" content={ description } />
      { image ? <link rel="image_src" href={ image } /> : null }
      { image ? <meta itemprop="image" content={ image } /> : null }

      <meta property="og:site_name" content={ site_name }/>
      <meta property="og:title" content={ title } />
      { description ? <meta property="og:description" content={ description } /> : null }
      <meta property="og:locale" content={ locales[lang] } />
      <meta property="og:type" content={ type } />
      { image ? <meta property="og:image" content={ image } /> : null }
      { width ? <meta property="og:image:width" content={ width } /> : null }
      { height ? <meta property="og:image:height" content={ height } /> : null }
      <meta name="og:image:secure_url" content={ image } />
      <meta property="fb:pages" content="..." />
      <meta name="theme-color" content={ color } />
      {/* change type of twitter if there is no image? */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={ title } />
      { description ? <meta name="twitter:description" content={ description } /> : null }
      <meta name="twitter:site" content="@..." />
      <meta name="twitter:image" content={ image } />
      
    </Helmet>
  )
}

export default Meta