import React, { Suspense } from 'react';

import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls, useGLTF } from "@react-three/drei";
import Img from 'react-cool-img'

const Model = (props) => {
    const gltf = useGLTF(props.expansionData.boxModel.model);
    return (
      <>
        <primitive object={gltf.scene} scale={props.expansionData.boxModel.scale} rotation={[props.expansionData.boxModel.x, props.expansionData.boxModel.y, props.expansionData.boxModel.z]}/>
      </>
    );
  };

  export default function ExpansionBoxModel(props) {
    
    return (
        <React.Fragment>
        {!props.expansionData?.boxModel?.model || !props.viewingModel ?
            <Img src={props.expansionData.boxImage} alt={props.expansionData.name} style={props.matches ? {width:'150px', height:'150px', margin:'0px'}:{width:'500px', height:'500px', margin:'0px'}}/>:
        <Canvas style={{ height: props.matches?'150px':'500px', width: props.matches?'150px':'500px' }}>
          <Suspense fallback={null}>
            <OrbitControls autoRotate enableZoom={false} maxPolarAngle={Math.PI / 2} minPolarAngle={Math.PI / 2} enablePan={false} />
            <ambientLight intensity={1.5}/>
            <Environment preset="sunset" />
            <Model expansionData={props.expansionData} matches={props.matches}/>
          </Suspense>
        </Canvas>}
        </React.Fragment>
    );
  }