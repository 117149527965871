import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
//import js file
import $ from 'jquery';
//import scss file
import './foil.scss';

const FoilCard = (props) => { 
  const matches = useMediaQuery('(max-width:600px)');
      //useEffect for media query
      React.useEffect(() => {
  
      } , [matches])
React.useEffect(() => {
  var x;
  //get .card class where its image is props.image
  var $cards = $(".card");
  //get all cards
  var $style = $(".hover");
  
  $cards
    .on("mousemove touchmove", function(e) { 
      // normalise touch/mouse
      var pos = [e.offsetX,e.offsetY];
      e.preventDefault();
      if ( e.type === "touchmove" ) {
        pos = [ e.touches[0].clientX, e.touches[0].clientY ];
      }
      var $card = $(this);
      // math for mouse position
      var l = pos[0];
      var t = pos[1];
      var h = $card.height();
      var w = $card.width();
      var px = Math.abs(Math.floor(100 / w * l)-100);
      var py = Math.abs(Math.floor(100 / h * t)-100);
      var pa = (50-px)+(50-py);
      // math for gradient / background positions
      var lp = (50+(px - 50)/1.5);
      var tp = (50+(py - 50)/1.5);
      var px_spark = (50+(px - 50)/7);
      var py_spark = (50+(py - 50)/7);
      var p_opc = 20+(Math.abs(pa)*1.5);
      var ty = ((tp - 50)/2) * -1;
      var tx = ((lp - 50)/1.5) * .5;
      // css to apply for active card
      var grad_pos = `background-position: ${lp}% ${tp}%;`
      var sprk_pos = `background-position: ${px_spark}% ${py_spark}%;`
      var opc = `opacity: ${p_opc/100};`
      var tf = `transform: rotateX(${ty}deg) rotateY(${tx}deg)`
      var bgimage = `background-image: url(${props.image})`
  
      
      // need to use a <style> tag for psuedo elements
      var style = `
        .card:hover:before { ${grad_pos} }  /* gradient */
        .card:hover:after { ${sprk_pos} ${opc} }   /* sparkles */ 
        .card {${bgimage}  } 
        .card:animated {${bgimage}  } 
      `
      // set / apply css class and style
      $cards.removeClass("active");
      $card.removeClass("animated");
      $card.attr( "style", tf );
      $style.html(style);
      if ( e.type === "touchmove" ) {
        return false; 
      }
      clearTimeout(x);
    }).on("mouseout touchend touchcancel", function() {
      // remove css, apply custom animation on end
      var $card = $(this);
  
      $card.removeAttr("style");
      x = setTimeout(function() {
        $card.addClass("animated");
      },2500);
    });
      
}, []);

      return (
          <React.Fragment>  
              <section className="cards">
                  <div className="card new animated" style={{backgroundImage: `url(${props.image})`, boxShadow: '0px 0px 20px rgba(251, 255, 0,1)'}}></div>
              </section>
              <style className="hover"></style>
          </React.Fragment>
          );
}
export default FoilCard;