import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, TextField, Grid, Paper, Divider  } from '@mui/material';
//icons
import StarIcon from '@mui/icons-material/Star';
import LandscapeIcon from '@mui/icons-material/Landscape';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import _ from 'lodash';
import ICON from '../../img/icon.png';

const CardAbility = (props) => { 
    const matches = useMediaQuery('(max-width:600px)');

    function getAbilityName(type) {
        switch(type){
            case 'character': return 'Character Ability';
            case 'location': return 'Location Effect';
            case 'virus': return 'Virus Effect';
            case 'equipment': return 'Card Effect';
            case 'sacrifice': return 'Sacrifice Effect';
            case 'god': return 'God Ability';
            case 'galaxy': return 'Galaxy Ability';
            case 'ritual': return 'Ritual Ability';
            default : return 'Ability';
        }
    }

    function getAbilityIcon(type) {
        switch(type){
            case 'location': return <LandscapeIcon style={matches ? {fontSize:'12px'} : {}}/>;
            case 'virus': return <CoronavirusIcon style={matches ? {fontSize:'12px'} : {}}/>;
            default: return <StarIcon style={matches ? {fontSize:'12px'} : {}}/>;
        }
    }

    function getAbilityColor(type) {
        switch(type){
            case 'character': return '#a60000';
            case 'location': return '#3d3d3d';
            case 'virus': return '#7224a3';
            case 'equipment': return 'green';
            case 'sacrifice': return 'blue';
            case 'god': return '#333333';
            case 'galaxy': return '#440491';
            case 'ritual': return '#23306e';
            default : return 'black';
        }
    }
    
    return (props.cardData.ability === '' || _.isUndefined(props.cardData.ability) ? null :
        <Paper style={{backgroundColor: getAbilityColor(props.cardType), color:'white', padding:'10px',}}>
            <Grid item xs={12} md={12} lg={12}   >
                <Typography variant={matches ? 'body1' : 'h4'} style={matches ? {fontSize:'12px'} : {}}>
                    {getAbilityIcon(props.cardType)} {getAbilityName(props.cardType).toUpperCase()}</Typography>
            </Grid>
            <Grid item  md={6} lg={6} xs={12}  >
                <Typography variant={matches ? 'body1' : 'h4'}></Typography>
            </Grid>
            <Divider style={{backgroundColor:'white', marginBottom:'10px'}}></Divider>
            <Grid item  md={12} lg={12} xs={12}  >
                {props.edit ? 
                <TextField id="outlined-basic"  InputProps={{ style: { fontSize: '1rem', color:'white'} }} multiline rows={4} 
                fullWidth
                label={getAbilityName(props.cardType)} variant="outlined"  value={props.cardData.ability} 
                onChange={(e) => props.setCardData({...props.cardData, ability: e.target.value})} />
                :
                <Typography variant={'body1'} style={matches ? {fontSize:'12px'} : {}}>  <span
                dangerouslySetInnerHTML={{
                  __html: props.cardData.ability.replace(/spell counter/gi, (match) => `<img src=${ICON} alt='spellcounter' width='16px' style=${{marginRight:'6px'}}/>&nbsp;<b>${match.toUpperCase()}</b>`)
                  .replace(/heads:/gi, (match) => `<br/><b>Heads:</b>`).replace(/tails:/gi, (match) => `<br/><b>Tails:</b>`),

                }}
              /> </Typography>}
            </Grid>
        </Paper>
    );
}

export default CardAbility;