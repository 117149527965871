import React from "react";
import Grid from '@mui/material/Grid';
import { Button, Divider, Box, Container, Typography } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import { useCards } from "../../providers/cardProvider";
import FoilCard from "../../components/foil/FoilCard";

const Gallery = (props) => {

  const matches = useMediaQuery('(max-width:600px)');
  const { gallery } = useCards();
  const [cards, setCards] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  React.useEffect(() => {
  
        setLoading(true);
        let response = [];
        if(!sessionStorage.getItem('gallerys'))
          {
            response = gallery
            console.log(gallery)
            setCards(response);
            setLoading(false);
            
          } else {
            response = JSON.parse(sessionStorage.getItem('gallerys'))
            setCards(response);
            setLoading(false);
          }
        


  } , [])
  React.useEffect(() => {
  

} , [matches])


  return (
    <div>
        
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              '#075d8e',
            flexGrow: 1,
            width: '100%',
            marginTop:"10%",
          }}
        >
        <Container maxWidth="lg" sx={{ p: 4 }}>
        {loading ? <Skeleton variant={'circular'} width={'70px'} height={'70px'} sx={{marginLeft:'46%', marginBottom:'5%'}}></Skeleton> : null}
        {loading ? <Typography variant="h5" component="h2" gutterBottom>Loading...</Typography> : <Typography variant="h5" component="h2" gutterBottom> Commission Gallery</Typography>}
        <Grid container spacing={1}>
            <Grid item>
                <Button variant="contained" color="primary" onClick={() => navigate(-1)}>Back</Button>
            </Grid>

        </Grid>
        <Divider style={{margin:'10px'}} />
        
        <Grid container spacing={1}>
             {loading && 
             <Skeleton variant="rect" width={'1400px'} height={'1500px'}></Skeleton>}   
            {!loading &&
            cards.map((card, index) => {
                return (
                    <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                            
                            <FoilCard image={card.cardImage} key={index} />
                            
                      
                    </Grid>
                )

            })
            }
      
            
         

        </Grid>
        
        <Divider/>
        </Container>
      </Box>
    </div>
  );
};

export default Gallery;