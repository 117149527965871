import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, FormControl, Select, MenuItem} from '@mui/material';
//icons


const CardFranchise = (props) => { 
    const matches = useMediaQuery('(max-width:600px)');
    
    return (
        props.edit ? 
            <FormControl fullWidth style={{margin:'20px'}}>
            <Select
                name="Franchise"
                value={props.cardData.Franchise}
                label="Franchise"
                variant='outlined'
                //e.target.value substring to seperate between -
                onChange={(e) => props.setCardData({...props.cardData, Franchise: e.target.value,
                logoImage: props.grabLogoImage(e.target.value)
                })}
                //onChange={(e) => {  setCharacterData({...characterData, Franchise: e.target.value,})}}
            ><MenuItem value="">None</MenuItem>
                {JSON.parse(sessionStorage.getItem('logos')).map((logo) => {
                    return (
                        <MenuItem key={logo.name} value={logo.name}><img src={logo.image} style={{width:'48px'}} alt={logo.name+ ' image'}/>{logo.name}</MenuItem>
                    )
                })}
                
                
            </Select>
            </FormControl>
        : 
          <Typography variant={'body1'} style={matches ? {fontSize:'12px'} : {}}><b>Franchise: </b>{props.cardData.Franchise}  </Typography>
        
    );
}

export default CardFranchise;