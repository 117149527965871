import * as React from 'react';
import { Box, Container, Grid, Button } from '@mui/material';
import DokuGrid from '../components/DokuGrid';
import { useAuth } from '../providers/authProvider';

function Doku() {
  const { currentUser, loading } = useAuth();
  const [edit, setEdit] = React.useState(false);
  React.useEffect(() => {
   
  }, [edit]);
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: '#075d8e',
        flexGrow: 1,
        width: '100%',
        marginTop: '10%',
        display: 'flex',
        justifyContent: 'center', // Horizontally center content
        alignItems: 'center', // Vertically center content
      }}
    >
      <Container>
        {currentUser.email === 'mickemoosebyond@gmail.com' ?
        <Grid container sx={6} spacing={1} direction="row" alignItems="center"
            justifyContent="center" style={{marginBottom:'20px'}}>
            <Button variant="contained" onClick={()=>setEdit(!edit)}>{edit?'Cancel':'Make new Doku'}</Button>
        </Grid>:null}
        <Grid container sx={6} spacing={1} direction="row" alignItems="center"
            justifyContent="center" >
            <DokuGrid editing={edit}/>
        </Grid>
      </Container>
    </Box>
  );
}

export default Doku;
