import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, TextField, Grid  } from '@mui/material';

const CardName = (props) => { 
    const matches = useMediaQuery('(max-width:600px)');
    let fontSize = '24px'; // Default font size

    // Set a maximum font size based on the length of the name
    if (props.cardData.name.length > 20 && !props.cardData.freenian) {
      fontSize = '18px';
    }
  
    else if (props.cardData.name.length > 20 && props.cardData.freenian) {
        fontSize = '56px';
    }
    // Set a minimum font size based on the length of the name
    if (props.cardData.name.length < 5 && !props.cardData.freenian) {
      fontSize = '28px';
    }

    // Set a minimum font size based on the length of the name
    else if (props.cardData.name.length < 5 && props.cardData.freenian) {
        fontSize = '32px';
    }

    if (props.cardData.name.length > 5 && props.cardData.name.length < 20 && props.cardData.freenian) {
        fontSize = '40px';
      }

    return (
        <Grid item xs={8} md={8} lg={8}  >
            {props.edit ? 
            <TextField  InputProps={{ style: { fontSize: '1.5rem'} }}
                label="Name" variant="outlined" value={props.cardData.name} onChange={(e) => props.setCardData({...props.cardData, name: e.target.value})} />
            : 
            <Typography variant={matches ? 'body1' : 'h4'} style={{
                fontFamily:props.cardData.freenian ? 'Sectar' : 'Heroes Legend', fontSize: fontSize, color:props.cardData.type === 'galaxy'?'white':'black',
                WebkitTextStroke: props.cardData.type === 'galaxy'?'1.5px black':'1px white', // For Chrome and Safari
                textStroke: props.cardData.type === 'galaxy'?'1.5px black':'1px white', // For other browsers

            }} >{props.cardData.name}</Typography>
           
            }
            
        </Grid>
    );
}

export default CardName;