import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, TextField, FormControl, Select, MenuItem, Grid } from '@mui/material';
import Img from 'react-cool-img';
import { useNavigate } from 'react-router-dom';
//icons


const CardDescription = (props) => { 
    const matches = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();
    function getTitle(type) {
        switch(type){
            case 'character': return 'Description';
            case 'location': return 'Description';
            case 'virus': return 'Description';
            case 'equipment': return 'Description';
            case 'sacrifice': return 'Description';
            case 'god': return 'Summon Requirements';
            case 'galaxy': return 'Description';
            case 'ritual': return 'Summon Requirements';
            default: return 'Description';
        }
    }

    function getDesc(type) {
        switch(type){
            case 'god': return props.cardData.summonRequirements;
            case 'ritual': return props.cardData.summonRequirements;
            default: return props.cardData.description;
        }
    }
    
    return (
        props.cardType === 'galaxy' ?
        props.edit ? 
            <React.Fragment>           
            <FormControl fullWidth style={{margin:'20px'}}>
              <TextField id="outlined-basic"  InputProps={{ style: { fontSize: '1.5rem'} }} size="small"
             label="Materials" variant="outlined" sx={{maxWidth:'100px'}} value={props.cardData.materialCount} onChange={(e) => props.setCardData({...props.cardData, materialCount: e.target.value})} />
            
            <Select
                name="Franchise Material"
                value={props.cardData.Franchise}
                label="Franchise"
                variant='outlined'
                //e.target.value substring to seperate between -
                onChange={(e) => props.setCardData({...props.cardData, Franchise: e.target.value,
                logoImage: props.grabLogoImage(e.target.value)
                })}
                //onChange={(e) => {  setCharacterData({...characterData, Franchise: e.target.value,})}}
            ><MenuItem value="">None</MenuItem>
                {JSON.parse(sessionStorage.getItem('logos')).map((logo) => {
                    return (
                        <MenuItem key={logo.name} value={logo.name}><img src={logo.image} style={{width:'48px'}} alt={logo.name+ ' image'}/>{logo.name}</MenuItem>
                    )
                })}
                
                
            </Select>
            </FormControl></React.Fragment> 
        : 
            <Grid sx={{marginBottom:'30px'}}>
                <Typography variant={matches ? 'body1' : 'body1'}>
                {props.cardData.materialCount} <Img onClick={() => navigate('/franchises/franchise?name='+props.cardData.Franchise)}
        src={props.cardData.logoImage} style={{marginBottom:'-30px', width:'128px',cursor:'pointer'}}/> Cards needed as Materials to Summon</Typography></Grid>
        

        :
        props.edit ? 
            <TextField id="outlined-basic"  InputProps={{ style: { fontSize: '1rem'} }} multiline rows={6} fullWidth
                label={getTitle(props.cardType)} variant="outlined" value={getDesc(props.cardType)} 
                onChange={(e) => 
                    props.cardType === 'god' || props.cardType === 'ritual' ?
                    props.setCardData({...props.cardData, summonRequirements: e.target.value})
                    :
                    props.setCardData({...props.cardData, description: e.target.value})
                } />
            : 
            <Grid container>
                <Grid item xs={2} md={2} lg={2}  ></Grid>
                <Grid item xs={8} md={8} lg={8}  >
                    <Typography variant={'body1'} style={matches ? { fontSize:'12px'} : {marginBottom:'10%', maxWidth:'500px'}}>{getDesc(props.cardType)}</Typography>
                </Grid>
                <Grid item xs={2} md={2} lg={2}  ></Grid>
            </Grid>
            
    );
}

export default CardDescription;