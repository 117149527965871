import * as React from 'react';
import { firestore } from '../../firebase/firebase';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
//import { useLocation } from 'react-router-dom';
import { Divider, Typography, TextField, Button, Checkbox } from '@mui/material';
import { Input } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import _ from 'lodash';

const ExpansionForm = (props) => {

    //const location = useLocation()
    const [loading, setLoading] = React.useState(true);
    const [disabled, setDisabled] = React.useState(false);
    const [booster, setBooster] = React.useState(false);
    const [originData, setOriginData] = React.useState(sessionStorage.getItem('expansions') ? JSON.parse(sessionStorage.getItem('expansions')):[]);
    const [expansionData, setExpansionData] = React.useState(
        sessionStorage.getItem('expansionData') ? JSON.parse(sessionStorage.getItem('expansionData')) : props.expansionData);
    //let path = new URLSearchParams(location.search).get("docId");

    const matches = useMediaQuery('(max-width:600px)');

    //handle setCharacterData
    const handleSetExpansionData = (e, value) => {
        var newData = expansionData
        newData[e.target.name] = value
        setExpansionData({
            ...expansionData,
            [e.target.name]: value
        })
        sessionStorage.setItem('expansionData', JSON.stringify(newData));
        props.updateData(newData);
    }
    //useEffect for media query
    React.useEffect(() => {
        if(originData.filter(origin => origin.name === expansionData.name).length > 0){
            setDisabled(true);
        } else {
            setDisabled(false);
        }


  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [expansionData])
    //useEffect for media query
    React.useEffect(() => {
  
    } , [matches])
    React.useEffect(() => {
        
    } , [disabled])
    
    React.useEffect(() => {
        setLoading(true);
        let response = [];
        if(!sessionStorage.getItem('expansions')){
        firestore.collection('expansions').orderBy('name', 'asc').get()
            .then(querySnapshot => {
                response = querySnapshot.docs.map((doc) => {
                        return {
                        ...doc.data()
                        }
    
                    })
    
                    setOriginData(response);
                    setLoading(false);
                }).catch (e => {
                    
                    setLoading(false);
                });
            }
    } , [])

    const handleSubmit = (e) => {
        setLoading(true);
        
        props.saveData()
    }
    
    return (
    
    <React.Fragment>
        <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            '#075d8e',
          margin: "10%",
          overflow: 'hidden',
        }}
        >
        <Container  sx={{ p: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Paper elevation={2} style={{padding:'5px', height:'100%'}}>
                    <Typography align="center" variant='body1' style={{lineHeight:'2'}}> <b>Upload Box Image: </b> </Typography>
                    <Divider />
                        <Input
                            id="uploadImage"
                            type='file'
                            accept="image/*"
                            name="boxImage"
                            disabled={(props.saving && loading) || booster}
                            onChange={(e) => { handleSetExpansionData(e, e.target.files[0])}}
                            required/>
                    {expansionData.boxImage !== '' ? <img style={{width: '350px'}}
                    src={!_.isUndefined(expansionData.boxImage) && expansionData.boxImage instanceof File ? URL.createObjectURL(expansionData.boxImage) : expansionData.boxImage} 
                    alt="Uploaded"  /> : null}
                    </Paper>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Paper elevation={2} style={{padding:'5px'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Expansion Name: </b> </Typography>
                            </Grid>
 

                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                <TextField 
                                    value={expansionData.name}
                                    onChange={(e) => { handleSetExpansionData(e, e.target.value) }}
                                    fullWidth
                                    disabled={props.saving}
                                    required
                                    name="name"
                                    size="small"
                                    variant="outlined"
                                    label="Name"
                                />
                            </Grid>
                            {disabled ? <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                             <Typography align="center" style={{lineHeight:'2', color:'red', fontSize:'14px'}}> <b>{expansionData.name} is already in the database!</b> </Typography>
                                </Grid>:null}
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Booster Pack: </b> </Typography>
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                <Checkbox style={{bottom:'7px'}}
                                    checked={booster}
                                    onChange={(e) => { handleSetExpansionData(e, e.target.checked ? props.boosterpack : ''); setBooster(e.target.checked);
                                        if(e.target.checked){
                                            setExpansionData({
                                                ...expansionData,
                                                'boxModel': {
                                                    'model': 'https://firebasestorage.googleapis.com/v0/b/kfkbattaliondatabase.appspot.com/o/boxes%2FBoosterPack.glb?alt=media&token=2f05b65c-0c9a-4473-b58b-c7f915f45e3a',
                                                    'scale': 60,
                                                    'x': -6.5,
                                                    'y': 0,
                                                    'z': 0
                                                }
                                            })
                                        } else {
                                            setExpansionData({
                                                ...expansionData,
                                                'boxModel': {}
                                            })
                                        }

                                     }}
                                    disabled={props.saving}
                                    name="boxImage"
                                    size="small"

                                />
                            </Grid>

                            
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Print Year: </b> </Typography>
                            </Grid>

                            <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                                <TextField 
                                    value={expansionData.year}
                                    onChange={(e) => { handleSetExpansionData(e, e.target.value)}}
                                    fullWidth
                                    size="small"
                                    disabled={props.saving}
                                    //type='number'
                                    name="year"
                                    required
                                    variant="outlined"
                                    label="Year"
                                
                                />
                            </Grid>

                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Description: </b> </Typography>
                            </Grid>

                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10} sx={{marginBottom:'150px'}}>
                                <TextField 
                                    value={expansionData.desc}
                                    onChange={(e) => { handleSetExpansionData(e, e.target.value)}}
                                    fullWidth
                                    disabled={props.saving}
                                    size="small"
                                    name="desc"
                                    multiline
                                    rows={5}
                                    placeholder='Enter Description...'
                                    variant="outlined"
                                    
                                
                                />
                            </Grid>         
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Button variant="filled" onClick={() => handleSubmit()} disabled={props.saving || disabled}
            style={{backgroundColor:'white', color:'black'}}>
                {props.saving ? 'SUBMITTING...' : 'Submit Expansion'}
            </Button>
        </Container>
      </Box>    
    </React.Fragment>
    );
}
export default ExpansionForm;