import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, TextField, Grid, FormControl, InputLabel, Select, MenuItem  } from '@mui/material';

import activate from '../../../src/img/equiptypes/activate.png'
import equip from '../../../src/img/equiptypes/equip.png'
import continuous from '../../../src/img/equiptypes/continuous.png'
import ritual from '../../../src/img/equiptypes/ritual.png'
import quickplay from '../../../src/img/equiptypes/quickplay.png'
import Img from 'react-cool-img';



const CardHeader = (props) => { 
    const matches = useMediaQuery('(max-width:600px)');
    function getTitle(type) {
        switch(type){
            case 'character': return (
                <React.Fragment>
                <Grid item xs={6} md={6} lg={6}  >
                {props.edit ? 
                    <TextField id="outlined-basic"  InputProps={{ style: { fontSize: '1.5rem'} }} size="small"
                    label="ATK" variant="outlined" sx={{width:'30%'}} 
                    value={props.cardData.ATK} onChange={(e) => props.setCardData({...props.cardData, ATK: e.target.value})} />
                : 
                    <Typography variant={matches ? 'body1' : 'h5'}>ATK {props.cardData.ATK}  </Typography>}
            </Grid>
            <Grid item xs={6} md={6} lg={6}  >
                {props.edit ? 
                    <TextField id="outlined-basic"  InputProps={{ style: { fontSize: '1.5rem'} }} size="small"
                        label="DEF" variant="outlined" sx={{width:'30%'}} 
                        value={props.cardData.DEF} onChange={(e) => props.setCardData({...props.cardData, DEF: e.target.value})} />
                    : 
                <Typography variant={matches ? 'body1' : 'h5'}>DEF {props.cardData.DEF} </Typography>}
            </Grid>
            <Grid item xs={6} md={6} lg={6}  >
                <Typography variant={matches ? 'body1' : 'h5'}> </Typography>
            </Grid></React.Fragment>
            );
            case 'location': return (<React.Fragment>
                <Grid item xs={3} md={3} lg={3}  >
                    <Typography variant={matches ? 'body1' : 'h5'}>  </Typography>
                </Grid>
                <Grid item xs={6} md={6} lg={6}  >
                {props.edit ? <Typography variant={matches ? 'body1' : 'h5'} style={{marginBottom:'10px'}}>Locked in play for &nbsp;
                        <TextField id="outlined-basic"  InputProps={{ style: { fontSize: '1.5rem'} }} size="small"
                         label="Turns" variant="outlined" sx={{width:'50px'}} value={props.cardData.turns} onChange={(e) => props.setCardData({...props.cardData, turns: e.target.value})} />
                         &nbsp;turns.</Typography>
                      : 
                      <Typography variant={matches ? 'body1' : 'h5'} style={{marginBottom:'30px'}}>Locked in play for {props.cardData.turns} turns.</Typography>}
                    
                </Grid>
                <Grid item xs={2} md={2} lg={2}  >
                    <Typography variant={matches ? 'body1' : 'h5'}> </Typography>
                </Grid></React.Fragment>
            );
            case 'virus': return (
                <React.Fragment>
                <Grid item xs={12} md={12} lg={12}  >
                {props.edit ? 
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Virus Type</InputLabel>
                    <Select
                        value={props.cardData.vType}
                        label="Type"
                        name="eType"
                        variant='outlined'
                        onChange={(e) => { props.setCardData({...props.cardData, vType: e.target.value})}}
                    >
                      <MenuItem value="Φ">Φ Phi Virus</MenuItem>
                      <MenuItem value="Σ">Σ Sigma Virus</MenuItem>
                      <MenuItem value="Ω">Ω Omega Virus</MenuItem>
                        
                    </Select>
                  </FormControl>
                  :
                    <Typography variant={matches ? 'body1' : 'h5'}>{props.cardData.vType} Virus </Typography>}
                </Grid>
                <Grid item xs={12} md={12} lg={12}  >
                  {props.edit ?
                    <Typography variant={matches ? 'body1' : 'h5'}>Auto-Cure in &nbsp; 
                    <TextField id="outlined-basic"  InputProps={{ style: { fontSize: '1.5rem'} }} size="small"
                         variant="standard" sx={{width:'30px'}} value={props.cardData.turns} 
                         onChange={(e) => props.setCardData({...props.cardData, turns: e.target.value})} />
                    
                    &nbsp; turns </Typography>
                  :
                    <Typography variant={matches ? 'body1' : 'h5'}>Auto-Cure in {props.cardData.turns} turns </Typography>}
                </Grid>
                </React.Fragment>
            );
            case 'equipment': return (
                <React.Fragment>
                <Grid item xs={12} md={12} lg={12}  >
                  {props.edit ? 
                  <FormControl  fullWidth>
                    <InputLabel id="demo-simple-select-label">Equipment Type</InputLabel>
                    <Select
                        value={props.cardData.eType}
                        label="Type"
                        name="eType"
                        variant='outlined'
                        onChange={(e) => { props.setCardData({...props.cardData, eType: e.target.value})}}
                    >
                        <MenuItem value="Equip">Equip</MenuItem>
                        <MenuItem value="Activate">Activate</MenuItem>
                        <MenuItem value="Quickplay">Quickplay</MenuItem>
                        <MenuItem value="Continuous">Continuous</MenuItem>
                        <MenuItem value="Ritual">Ritual</MenuItem>
                        
                    </Select><Typography variant={matches ? 'body1' : 'h6'}> Type Card </Typography>
                  </FormControl>
                  :
                    <Grid container style={{justifyContent:'center'}}>
                        <Img style={{width:'32px',height:'32px'}} 
                        src={
                            props.cardData.eType === 'Equip' ? equip :
                            props.cardData.eType === 'Activate' ? activate :
                            props.cardData.eType === 'Quickplay' ? quickplay :
                            props.cardData.eType === 'Continuous' ? continuous :
                            props.cardData.eType === 'Ritual' ? ritual : null
                        } alt={'equip type icon'} />&nbsp;<Typography variant={matches ? 'body1' : 'h5'}>{props.cardData.eType} Type Card </Typography>
                    </Grid>
                    }
                </Grid>
                </React.Fragment>
            );
            case 'sacrifice': return (
                <React.Fragment>
                <Grid item xs={3} md={3} lg={3}  >
                {props.edit ? 
                        <TextField id="outlined-basic"  InputProps={{ style: { fontSize: '1.5rem'} }} size="small"
                         label="ATK" variant="outlined" sx={{width:'35%'}} value={props.cardData.ATK} onChange={(e) => props.setCardData({...props.cardData, ATK: e.target.value})} />
                      : 
                    <Typography variant={matches ? 'body1' : 'h5'}>ATK {props.cardData.ATK}  </Typography>}
                </Grid>
                <Grid item xs={6} md={6} lg={6}  >
                {props.edit ? 
                  <Typography variant={matches ? 'body1' : 'h4'}>Level <TextField id="outlined-basic"  InputProps={{ style: { fontSize: '1.5rem'} }} size="small"
                         label="Level" variant="outlined" sx={{width:'35%'}} value={props.cardData.Level} onChange={(e) => props.setCardData({...props.cardData, Level: e.target.value})} /></Typography>
                :
                    <Typography variant={matches ? 'body1' : 'h4'}>Level {props.cardData.Level} </Typography>}
                </Grid>
                <Grid item xs={3} md={3} lg={3}  >
                {props.edit ? 
                        <TextField id="outlined-basic"  InputProps={{ style: { fontSize: '1.5rem'} }} size="small"
                         label="DEF" variant="outlined" sx={{width:'35%'}} value={props.cardData.DEF} onChange={(e) => props.setCardData({...props.cardData, DEF: e.target.value})} />
                      : 
                    <Typography variant={matches ? 'body1' : 'h5'}>DEF {props.cardData.DEF} </Typography>}
                </Grid>
                </React.Fragment>
            );
            case 'god': return (
                <React.Fragment>
                <Grid item xs={6} md={6} lg={6}  >
                {props.edit ? 
                    <TextField id="outlined-basic"  InputProps={{ style: { fontSize: '1.5rem'} }} size="small"
                    label="ATK" variant="outlined" sx={{width:'30%'}} 
                    value={props.cardData.ATK} onChange={(e) => props.setCardData({...props.cardData, ATK: e.target.value})} />
                : 
                    <Typography variant={matches ? 'body1' : 'h5'}>ATK {props.cardData.ATK}  </Typography>}
            </Grid>
            <Grid item xs={6} md={6} lg={6}  >
                {props.edit ? 
                    <TextField id="outlined-basic"  InputProps={{ style: { fontSize: '1.5rem'} }} size="small"
                        label="DEF" variant="outlined" sx={{width:'30%'}} 
                        value={props.cardData.DEF} onChange={(e) => props.setCardData({...props.cardData, DEF: e.target.value})} />
                    : 
                <Typography variant={matches ? 'body1' : 'h5'}>DEF {props.cardData.DEF} </Typography>}
            </Grid>
            <Grid item xs={6} md={6} lg={6}  >
                <Typography variant={matches ? 'body1' : 'h5'}> </Typography>
            </Grid></React.Fragment>
            );
            case 'galaxy': return (
                <React.Fragment>
                <Grid item xs={6} md={6} lg={6}  >
                {props.edit ? 
                    <TextField id="outlined-basic"  InputProps={{ style: { fontSize: '1.5rem'} }} size="small"
                    label="ATK" variant="outlined" sx={{width:'30%'}} 
                    value={props.cardData.ATK} onChange={(e) => props.setCardData({...props.cardData, ATK: e.target.value})} />
                : 
                    <Typography variant={matches ? 'body1' : 'h5'}>ATK {props.cardData.ATK}  </Typography>}
            </Grid>
            <Grid item xs={6} md={6} lg={6}  >
                {props.edit ? 
                    <TextField id="outlined-basic"  InputProps={{ style: { fontSize: '1.5rem'} }} size="small"
                        label="DEF" variant="outlined" sx={{width:'30%'}} 
                        value={props.cardData.DEF} onChange={(e) => props.setCardData({...props.cardData, DEF: e.target.value})} />
                    : 
                <Typography variant={matches ? 'body1' : 'h5'}>DEF {props.cardData.DEF} </Typography>}
            </Grid>
            <Grid item xs={6} md={6} lg={6}  >
                <Typography variant={matches ? 'body1' : 'h5'}> </Typography>
            </Grid></React.Fragment>
            );
            case 'ritual': return (
                <React.Fragment>
                <Grid item xs={6} md={6} lg={6}  >
                {props.edit ? 
                    <TextField id="outlined-basic"  InputProps={{ style: { fontSize: '1.5rem'} }} size="small"
                    label="ATK" variant="outlined" sx={{width:'30%'}} 
                    value={props.cardData.ATK} onChange={(e) => props.setCardData({...props.cardData, ATK: e.target.value})} />
                : 
                    <Typography variant={matches ? 'body1' : 'h5'}>ATK {props.cardData.ATK}  </Typography>}
            </Grid>
            <Grid item xs={6} md={6} lg={6}  >
                {props.edit ? 
                    <TextField id="outlined-basic"  InputProps={{ style: { fontSize: '1.5rem'} }} size="small"
                        label="DEF" variant="outlined" sx={{width:'30%'}} 
                        value={props.cardData.DEF} onChange={(e) => props.setCardData({...props.cardData, DEF: e.target.value})} />
                    : 
                <Typography variant={matches ? 'body1' : 'h5'}>DEF {props.cardData.DEF} </Typography>}
            </Grid>
            <Grid item xs={6} md={6} lg={6}  >
                <Typography variant={matches ? 'body1' : 'h5'}> </Typography>
            </Grid></React.Fragment>
            );
            default: return (null);
        }
    }


    return (
        <Grid container spacing={2} sx={{marginBottom:'50px'}}>
            {getTitle(props.cardType)}
        </Grid>
    );
}

export default CardHeader;