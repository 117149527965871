import React, { useContext, useState, useEffect } from "react"
import { firestore } from "../firebase/firebase";

const CardContext = React.createContext()

export function useCards() {
  return useContext(CardContext)
}

export function CardProvider({ children }) {

    const [character, setCharacter] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const [sacrifice, setSacrifice] = useState([]);
    const [locations, setLocations] = useState([]);
    const [gallery, setGallerys] = useState([]);
    const [cards, setCards] = useState(sessionStorage.getItem('cards') ? JSON.parse(sessionStorage.getItem('cards')) : []);
    const [virus, setVirus] = useState([]);
    const [galaxy, setGalaxy] = useState([]);
    const [god, setGod] = useState([]);
    const [ritual, setRitual] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cardsLoading, setCardsLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [upcomingCount, setUpcomingCount] = useState(0);
    const [expansions, setExpansions] = useState([]);
    const [logos, setLogos] = useState([]);
  
  useEffect(() => {
    setLoading(true);
    setCardsLoading(true);
    let response = []
    let logoResponse = []
    let expansionResponse = []
    if(!sessionStorage.getItem('expansions')){
      firestore.collection('expansions').orderBy('name','asc').get()
      .then(querySnapshot => {
        expansionResponse = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
          }
        })
        sessionStorage.setItem('expansions', JSON.stringify(expansionResponse));
        setExpansions(expansionResponse)
      })
        
    } else {
      expansionResponse = JSON.parse(sessionStorage.getItem('expansions'));
      setExpansions(expansionResponse)
    }
    if(!sessionStorage.getItem('logos')){
      firestore.collection('logos').orderBy('name','asc').get()
      .then(querySnapshot => {
        logoResponse = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
          }
        })
        sessionStorage.setItem('logos', JSON.stringify(logoResponse));
        setLogos(logoResponse)
      })
        
    } else {
      logoResponse = JSON.parse(sessionStorage.getItem('logos'));
      setLogos(logoResponse)
    }
    if(!sessionStorage.getItem('cards')){
      firestore.collection('cards').orderBy('name','asc').get()
      .then(querySnapshot => {
        response = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
          }
        })
          setCharacter(response.filter(cards => cards.name === 'character')[0].cards)
          sessionStorage.setItem('characters', JSON.stringify(response.filter(cards => cards.name === 'character')[0].cards))
          setEquipment(response.filter(cards => cards.name === 'equipment')[0].cards)
          sessionStorage.setItem('equipments', JSON.stringify(response.filter(cards => cards.name === 'equipment')[0].cards))
          setSacrifice(response.filter(cards => cards.name === 'sacrifice')[0].cards)
          sessionStorage.setItem('sacrifices', JSON.stringify(response.filter(cards => cards.name === 'sacrifice')[0].cards))
          setLocations(response.filter(cards => cards.name === 'location')[0].cards)
          sessionStorage.setItem('locations', JSON.stringify(response.filter(cards => cards.name === 'location')[0].cards))
          setVirus(response.filter(cards => cards.name === 'virus')[0].cards)
          sessionStorage.setItem('viruses', JSON.stringify(response.filter(cards => cards.name === 'virus')[0].cards))
          setGalaxy(response.filter(cards => cards.name === 'galaxy')[0].cards)
          sessionStorage.setItem('galaxies', JSON.stringify(response.filter(cards => cards.name === 'galaxy')[0].cards))
          setGod(response.filter(cards => cards.name === 'god')[0].cards)
          sessionStorage.setItem('gods', JSON.stringify(response.filter(cards => cards.name === 'god')[0].cards))
          setRitual(response.filter(cards => cards.name === 'ritual')[0].cards)
          sessionStorage.setItem('rituals', JSON.stringify(response.filter(cards => cards.name === 'ritual')[0].cards))
          setGallerys(response.filter(cards => cards.name === 'gallery')[0].cards)
          sessionStorage.setItem('gallerys', JSON.stringify(response.filter(cards => cards.name === 'gallery')[0].cards))
          
          //add all to cards
          //count all cards

          setCards(response)
          sessionStorage.setItem('cards', JSON.stringify(response))

      }).catch(error => {
          console.log(error)
          setLoading(false);
          setCardsLoading(false);
      }).finally(() => {
          let count = 0;
          let count2 = 0;

          count += response.filter(cards => cards.name === 'ritual')[0].cards.filter(card => card.origin.length > 0).length
          count += response.filter(cards => cards.name === 'god')[0].cards.filter(card => card.origin.length > 0).length
          count += response.filter(cards => cards.name === 'galaxy')[0].cards.filter(card => card.origin.length > 0).length
          count += response.filter(cards => cards.name === 'virus')[0].cards.filter(card => card.origin.length > 0).length
          count += response.filter(cards => cards.name === 'location')[0].cards.filter(card => card.origin.length > 0).length
          count += response.filter(cards => cards.name === 'sacrifice')[0].cards.filter(card => card.origin.length > 0).length
          count += response.filter(cards => cards.name === 'equipment')[0].cards.filter(card => card.origin.length > 0).length
          count += response.filter(cards => cards.name === 'character')[0].cards.filter(card => card.origin.length > 0).length

          count2 += response.filter(cards => cards.name === 'ritual')[0].cards.filter(card => card.origin.length <= 0).length
          count2 += response.filter(cards => cards.name === 'god')[0].cards.filter(card => card.origin.length <= 0).length
          count2 += response.filter(cards => cards.name === 'galaxy')[0].cards.filter(card => card.origin.length <= 0).length
          count2 += response.filter(cards => cards.name === 'virus')[0].cards.filter(card => card.origin.length <= 0).length
          count2 += response.filter(cards => cards.name === 'location')[0].cards.filter(card => card.origin.length <= 0).length
          count2 += response.filter(cards => cards.name === 'sacrifice')[0].cards.filter(card => card.origin.length <= 0).length
          count2 += response.filter(cards => cards.name === 'equipment')[0].cards.filter(card => card.origin.length <= 0).length
          count2 += response.filter(cards => cards.name === 'character')[0].cards.filter(card => card.origin.length <= 0).length

          response.push({name: 'all', cards: count})
          setTotalCount(count)
          setUpcomingCount(count2)
          setLoading(false);
          setCardsLoading(false);
      })
    } else {
      
      setCharacter(JSON.parse(sessionStorage.getItem('characters')))
      setEquipment(JSON.parse(sessionStorage.getItem('equipments')))
      setSacrifice(JSON.parse(sessionStorage.getItem('sacrifices')))
      setLocations(JSON.parse(sessionStorage.getItem('locations')))
      setGallerys(JSON.parse(sessionStorage.getItem('gallerys')))
      setVirus(JSON.parse(sessionStorage.getItem('viruses')))
      setGalaxy(JSON.parse(sessionStorage.getItem('galaxies')))
      setGod(JSON.parse(sessionStorage.getItem('gods')))
      setRitual(JSON.parse(sessionStorage.getItem('rituals')))

      setExpansions(JSON.parse(sessionStorage.getItem('expansions')))
      setLogos(JSON.parse(sessionStorage.getItem('logos')))

      
      //add all these to response
      response.push({name: 'character', cards: JSON.parse(sessionStorage.getItem('characters'))})
      response.push({name: 'equipment', cards: JSON.parse(sessionStorage.getItem('equipments'))})
      response.push({name: 'sacrifice', cards: JSON.parse(sessionStorage.getItem('sacrifices'))})
      response.push({name: 'location', cards: JSON.parse(sessionStorage.getItem('locations'))})
      response.push({name: 'virus', cards: JSON.parse(sessionStorage.getItem('viruses'))})
      response.push({name: 'galaxy', cards: JSON.parse(sessionStorage.getItem('galaxies'))})
      response.push({name: 'god', cards: JSON.parse(sessionStorage.getItem('gods'))})
      response.push({name: 'ritual', cards: JSON.parse(sessionStorage.getItem('rituals'))})
      //count all cards
      let count = 0;
      response.forEach((card) => {
        count += card.cards.filter(card => card.origin.length >0).length
      })
      let count2 = 0;
      response.forEach((card) => {
        count2 += card.cards.filter(card => card.origin.length <=0).length
      })
      setTotalCount(count)
      setUpcomingCount(count2)
      setCards(response)
      setLoading(false);
      setCardsLoading(false);
    }
    //eslint-disable-next-line
  }, [])

  const value = {
    character,
    equipment,
    sacrifice,
    gallery,
    locations,
    virus,
    galaxy,
    god,
    ritual,
    loading,
    cards,
    cardsLoading,
    totalCount,
    upcomingCount,
    expansions,
    logos
  }

  return (
    <CardContext.Provider value={value}>
      {children}
    </CardContext.Provider>
  )
}