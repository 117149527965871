import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import bleed from '../../img/bleed.png';
import Img from 'react-cool-img';
import { useCards } from '../../providers/cardProvider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";
import Sleeve from '../../img/Sleeve.png';
import Sparkles from "../../components/Sparkles";
import ItemsCarousel from 'react-items-carousel';

const CardName = (props) => { 
    const navigate = useNavigate();
    const { character, equipment, locations, virus, ritual, sacrifice, god, galaxy } = useCards();
    const matches = useMediaQuery('(max-width:1024px)');
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;

    useEffect(() => {

    }, [matches])

    function getCardList(cardType) {
        switch(cardType) {
          case 'character': return character;
          case 'equipment': return equipment;
          case 'location': return locations;
          case 'virus': return virus;
          case 'ritual': return ritual;
          case 'sacrifice': return sacrifice;
          case 'god': return god;
          case 'galaxy': return galaxy;
          default: return [];
        }
      }

    const getCardIndex = (id, name, type) => {
        let cardList = []
        cardList = getCardList(type).filter(card=> stripName(card.name) === name)
        let index = cardList.findIndex((item) => {
          return item.id === id;
        })
    
        return index;
      }


    //function to return name without numbers
    const stripName = (name) => {
        let cardName = name.toString().toLowerCase().replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '')
        return cardName;
    }

      

    const goToCard = (id, name, type) => {
        navigate(`/cards/${type}/${name}-${id}`, { state: { id: name }});
        window.location.reload();
    }
   
    return (
        
            <div style={{ padding: `0 ${chevronWidth}px`, overflowY:'hidden', width:'100%'}}>
                <Typography style={{color:'white', fontSize:'18px', fontWeight:'bold', textAlign:'center', textShadow:'2px 2px 4px #000000'}}>Variants</Typography>
                <ItemsCarousel style={{paddingBottom:'20%' , overflowY:'hidden', width:'100%'}}
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={matches ? 2 : 6}
                    gutter={20}
                    leftChevron={<button>{'<'}</button>}
                    rightChevron={<button>{'>'}</button>}
                    outsideChevron
                    chevronWidth={chevronWidth}
                >


            {props.variants.map(variant => {
                
                return(
                    
                        

                        variant.foil || variant.cloudyfoil ?
                        
                            <Sparkles style={{overflow:'hidden'}}>
                            <Typography style={{color:'white', fontSize:'14px', fontWeight:'bold', textAlign:'center', textShadow:'2px 2px 4px #000000'}}>By {variant.artist}</Typography>
                            <Img placeholder={Sleeve} src={variant.cardImage} alt={variant.name} style={{width:'200px', height:'auto', marginTop:'10px',
                            maskImage: `url(${bleed})`,
                            maskSize: '200px auto',
                            maskRepeat: 'no-repeat',
                            maskPosition: 'center',
                            WebkitMaskImage: `url(${bleed})`,
                            WebkitMaskSize: '200px auto',
                      }}
                          onClick={() => goToCard(getCardIndex(variant.id, stripName(variant.name), variant.type), stripName(variant.name), variant.type)}
                      /></Sparkles>:<React.Fragment>
                        <Typography style={{color:'white', fontSize:'14px', fontWeight:'bold', textAlign:'center', textShadow:'2px 2px 4px #000000'}}>By {variant.artist}</Typography>
                            <Img placeholder={Sleeve} src={variant.cardImage} alt={variant.name} style={{width:'200px', height:'auto', marginTop:'10px',
                              maskImage: `url(${bleed})`,
                              maskSize: '200px auto',
                              maskRepeat: 'no-repeat',
                              maskPosition: 'center',
                              WebkitMaskImage: `url(${bleed})`,
                              WebkitMaskSize: '200px auto',
                        }}
                            onClick={() => goToCard(getCardIndex(variant.id, stripName(variant.name), variant.type), stripName(variant.name), variant.type)}
                        /></React.Fragment>
                     
                    
                )
            })

            }   
                </ItemsCarousel>
            </div>         
        
    );
}

export default CardName;