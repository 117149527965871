import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Paper, Divider, TextField, Typography, FormControl, MenuItem, Select, Grid } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Img from 'react-cool-img';
import { useNavigate } from 'react-router-dom';

const LocationDetails = (props) => { 
    const matches = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();
    
    return (
        <Paper sx={{marginBottom:'15px', maxWidth:'50%', marginLeft:'25%', backgroundColor:'rgba(255, 255, 255, 0.5)'}}>
            <Grid container spacing={2}>
                <Grid item xs={6} md={6} lg={6}  >
                    <Typography variant={'body1'} style={matches ? { fontSize:'12px'} : {}}><ArrowUpwardIcon style={{color:'green', fontSize:'32px'}}/></Typography>
                    <Divider/>
                    {props.edit ?
                    <FormControl>
                    <Select
                        name="FranchiseBuff"
                        value={props.cardData.FranchiseBuff}
                        label="FranchiseBuff"
                        variant='outlined'
                        onChange={(e) => props.setCardData({...props.cardData, FranchiseBuff: e.target.value,
                        logoImage: props.grabLogoImage(e.target.value)
                        })}
                    ><MenuItem value="">None</MenuItem>
                        {JSON.parse(sessionStorage.getItem('logos')).map((logo) => {
                            return (
                                <MenuItem key={logo.name} value={logo.name}><img src={logo.image} style={{width:'64px'}} alt={logo.name+ ' image'}/></MenuItem>
                            )
                        })}
                        
                        
                    </Select>
                    </FormControl>
                    :
                    <Img src={props.cardData.buffImage} alt={props.cardData.FranchiseBuff} style={matches ? {width:'64px'}: {width:'96px',cursor:'pointer'}} onClick={() => navigate('/franchises/franchise?name='+props.cardData.FranchiseBuff)}/>}
                    {props.edit ? 
                    <p><TextField id="outlined-basic" size="small" variant="standard" fullWidth
                    label="Buff" sx={{width:'200px'}} value={props.cardData.buff} InputProps={{ style: { fontSize: '1.5rem'} }}
                    onChange={(e) => props.setCardData({...props.cardData, buff: e.target.value})} /></p>
                    : <Typography variant={'h5'} style={matches ? { fontSize:'12px'} : {}}>{props.cardData.buff}</Typography>}
                </Grid>
                <Grid item xs={6} md={6} lg={6}  >
                    <Typography variant={'body1'} style={matches ? { fontSize:'12px'} : {}}><ArrowDownwardIcon style={{color:'red', fontSize:'32px'}}/></Typography>
                    <Divider/>
                    {props.edit ?
                    <FormControl>
                    <Select
                        name="FranchiseDebuff"
                        value={props.cardData.FranchiseDebuff}
                        label="FranchiseDebuff"
                        variant='outlined'
                        //e.target.value substring to seperate between -
                        onChange={(e) => props.setCardData({...props.cardData, FranchiseDebuff: e.target.value,
                        logoImage: props.grabLogoImage(e.target.value)
                        })}
                        //onChange={(e) => {  setCharacterData({...characterData, Franchise: e.target.value,})}}
                    ><MenuItem value="">None</MenuItem>
                        {JSON.parse(sessionStorage.getItem('logos')).map((logo) => {
                            return (
                                <MenuItem key={logo.name} value={logo.name}><img src={logo.image} style={{width:'64px'}} alt={logo.name+ ' image'}/></MenuItem>
                            )
                        })}
                        
                        
                    </Select>
                    </FormControl>
                    :
                    <Img src={props.cardData.debuffImage} alt={props.cardData.FranchiseDebuff} style={matches ? {width:'64px'}: {width:'96px',cursor:'pointer'}} onClick={() => navigate('/franchises/franchise?name='+props.cardData.FranchiseDebuff)}/>}
                    {props.edit ? 
                    <p><TextField id="outlined-basic" size="small" variant="standard" fullWidth
                    label="Debuff" sx={{width:'200px'}} value={props.cardData.debuff} InputProps={{ style: { fontSize: '1.5rem'} }}
                    onChange={(e) => props.setCardData({...props.cardData, debuff: e.target.value})} /></p>
                    : <Typography variant={'h5'} style={matches ? { fontSize:'12px'} : {}}>{props.cardData.debuff}</Typography>}
                </Grid>
            </Grid>
        </Paper>
    );
}

export default LocationDetails;