
import React from 'react';
import './index.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './providers/authProvider'
import { CardProvider } from './providers/cardProvider'
import ScrollToTop from './Admin/components/ScrollToTop';
ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <CardProvider>
        <ScrollToTop />
          <App/>
      </CardProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
)