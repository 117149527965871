import React, {useState, useEffect} from 'react';
import { Grid, Button, Modal, Paper, Typography, TextField, Divider, MenuItem, FormControl, InputLabel, Select, Alert } from '@mui/material';
import { useCards } from "../providers/cardProvider";
import Img from 'react-cool-img';
import Sleeve from '../img/Sleeve.png';
import BrushIcon from '@mui/icons-material/Brush';
import _ from 'lodash';
import { firestore } from '../firebase/firebase';
import moment from 'moment';


const DokuGrid = (props) => {
    const [open, setOpen] = useState(false);
    const [selectedCell, setSelectedCell] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const { character, equipment, sacrifice, galaxy, god, ritual, locations, virus } = useCards();
    const [selectedRow, setSelectedRow] = useState(0);
    const [selectedColumn, setSelectedColumn] = useState(0);
    const [energy, setEnergy] = useState(10);

    const [dokuData, setDokuData] = useState(
        //check sessionStorage for dokuData
        sessionStorage.getItem('dokuData') ? JSON.parse(sessionStorage.getItem('dokuData')) : {}
    );

    const [cards, setCards] = useState([]);

    const [rowFilters, setRowFilters] = useState([{id:'',label:''},{id:'',label:''},{id:'',label:''}]);
    const [columnFilters, setColumnFilters] = useState([{id:'',label:''},{id:'',label:''},{id:'',label:''}]);

    const [cells, setCells] = useState([
        {id: 'topLeft', value: {}},
        {id: 'topMiddle', value: {}},
        {id: 'topRight', value: {}},
        {id: 'leftMiddle', value: {}},
        {id: 'middleMiddle', value: {}},
        {id: 'rightMiddle', value: {}},
        {id: 'bottomLeft', value: {}},
        {id: 'bottomMiddle', value: {}},
        {id: 'bottomRight', value: {}},
    ]);

    useEffect(() => {
        // Any effect you want to run on component mount

    }, [open, selectedCell, searchText, cells, columnFilters, rowFilters, loading, energy]);
    useEffect(() => {
        // put character, sacrifice, galaxy, god and ritual into one array called cards
        setLoading(true);

        if(!props.editing && !sessionStorage.getItem('dokuData') && _.isEmpty(dokuData)){
            //get todays or most recent date that is before today from firestore
            firestore.collection('dokus').orderBy('date', 'desc').limit(1).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    setDokuData(doc.data());
                    console.log(doc.data())
                    sessionStorage.setItem('dokuData', JSON.stringify(doc.data()));
                });
            }).catch((error) => {
                console.log(error)
            })

        }
        setRowFilters(dokuData.rowFilters);
        setColumnFilters(dokuData.columnFilters);

        //if rowFilters and columnFilters are undefined at this point, set them to default state
        if(_.isEmpty(dokuData.rowFilters) && _.isEmpty(dokuData.columnFilters)){
            setRowFilters(
                [{ id: '', label: '' }, { id: '', label: '' }, { id: '', label: '' }]
            );
            setColumnFilters(
                [{ id: '', label: '' }, { id: '', label: '' }, { id: '', label: '' }]
            );
        }

        let cards = [];
        character.forEach((card) => cards.push(card));
        sacrifice.forEach((card) => cards.push(card));
        galaxy.forEach((card) => cards.push(card));
        god.forEach((card) => cards.push(card));
        ritual.forEach((card) => cards.push(card));

        setCards(cards);
        setLoading(false);


    }, []);

    const handleOpen = (cell,row,column) => {
        setOpen(true);
        setSelectedRow(row);
        setSelectedColumn(column);
        setSelectedCell(cell);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let filters = [
        {id:'fromPrazonic', label: 'Prazonic Expansion', string: 'Card must originate from this expansion', key:'origin', value:'Prazonic Expansion'},
        {id:'fromHazorbic', label: 'Hazorbic Expansion', string: 'Card must originate from this expansion', key:'origin', value:'Hazorbic Expansion'},
        {id:'fromFroesey', label: 'Froesey Expansion', string: 'Card must originate from this expansion', key:'origin', value:'Froesey Expansion'},
        {id:'fromGlockwork', label: 'Glockwork Expansion', string: 'Card must originate from this expansion', key:'origin', value:'Glockwork Expansion'},
        {id:'fromSuperPowered', label: 'Super Powered Expansion', string: 'Card must originate from this expansion', key:'origin', value:'Super Powered Expansion'},
        {id:'fromRitual', label: 'Ritual Expansion', string: 'Card must originate from this expansion', key:'origin', value:'Ritual Expansion'},
        {id:'fromFreenian', label: 'Freenian Expansion', string: 'Card must originate from this expansion', key:'origin', value:'Freenian Expansion'},
        {id:'fromFirstEdition', label: 'First Edition', string: 'Card must originate from this set', key:'origin', value:'First Edition'},
        {id:'fromSecondEdition', label: 'Second Edition', string: 'Card must originate from this set', key:'origin', value:'Second Edition'},
        {id:'fromThirdEdition', label: 'Third Edition', string: 'Card must originate from this set', key:'origin', value:'Third Edition'},
        {id:'isMale', label: '♂', string: 'Card must be male'},
        {id:'isFemale', label: '♀', string: 'Card must be female'},
        {id:'isHybrid', label: 'Hybrid', string: 'Card must be a Hybrid Character Card', key:'hybrid', value:true},
        {id:'isCharacter', label: 'Character', string: 'Card must be a Character Card', key:'type', value:'character'},
        {id:'isFreenian', label: 'Freenian', string: 'Card must be a Freenian Character', key:'freenian', value:true},
        {id:'isRitual', label: 'Ritual', string: 'Card must be a Ritual Card', key:'type', value: 'ritual'},
        {id:'isGod', label: 'God', string: 'Card must be a God Card', key:'type', value: 'god'},
        {id:'isGalaxy', label: 'Galaxy', string: 'Card must be a Galaxy Card', key:'type', value: 'galaxy'},
        {id:'isSacrifice', label: 'Sacrifice', string: 'Card must be a Sacrifice Card', key:'type', value: 'sacrifice'},
        {id:'isSacLvl1', label: 'Sacrifice Lvl 1', string: 'Card must be a Sacrifice Card of this Level', key:'Level', value: '1'},
        {id:'isSacLvl2', label: 'Sacrifice Lvl 2', string: 'Card must be a Sacrifice Card of this Level', key:'Level', value: '2'},
        {id:'isSacLvl3', label: 'Sacrifice Lvl 3', string: 'Card must be a Sacrifice Card of this Level', key:'Level', value: '3'},
        {id:'isSacLvl4', label: 'Sacrifice Lvl 4', string: 'Card must be a Sacrifice Card of this Level', key:'Level', value: '4'},
        {id:'fromMooseOnTheLoose', label: 'Moose On The Loose', string: 'Card must be of this franchise', key:'Franchise', value:'Moose On The Loose'},
        {id:'fromComedyBros', label: 'Comedy Bros', string: 'Card must be of this franchise', key:'Franchise', value:'Comedy Bros'},
        {id:'fromBovinianTales', label: 'Bovinian Tales', string: 'Card must be of this franchise', key:'Franchise', value:'Bovinian Tales'},
        {id:'fromCTOOP', label: 'C.T.O.O.P', string: 'Card must be of this franchise', key:'Franchise', value:'CTOOP'},
        {id:'fromCartoonKnights', label: 'Cartoon Knights', string: 'Card must be of this franchise', key:'Franchise', value:'Cartoon Knights'},
        {id:'fromDiaperDude', label: 'Diaper Dude', string: 'Card must be of this franchise', key:'Franchise', value:'Diaper Dude'},
        {id:'fromBeccanators', label: 'The Beccanators', string: 'Card must be of this franchise', key:'Franchise', value:'The Beccanators'},
        {id:'fromStarcrashers', label: 'Starcrashers', string: 'Card must be of this franchise', key:'Franchise', value:'Starcrashers'},
        {id:'fromPieceWars', label: 'Piece Wars', string: 'Card must be of this franchise', key:'Franchise', value:'Piece Wars'},
        {id:'fromSFG', label: 'Super Friggin Powerful', string: 'Card must be of this franchise', key:'Franchise', value:"Super Friggin' Powerful"},
        {id:'fromSpawn', label: 'Spawn Of The Void', string: 'Card must be of this franchise', key:'Franchise', value:"Spawn Of The Void"},
        {id:'fromSuperFriends', label: 'Super Friends', string: 'Card must be of this franchise', key:'Franchise', value:"Super Friends"},
        {id:'fromSkyBattalion', label: 'Sky Battalion', string: 'Card must be of this franchise', key:'Franchise', value:"Sky Battalion"},
        {id:'fromMisc', label: 'Miscellaneous', string: 'Card must be of this franchise', key:'Franchise', value:"Misc."},
        {id:'fromJuicySquirts', label: 'Juicy Squirts', string: 'Card must be of this franchise', key:'Franchise', value:"Juicy Squirts"},
        {id:'atk10', label: 'ATK 10', string: 'Card must have an ATK of 10', key:'ATK', value:"10"},
        {id:'def10', label: 'DEF 10', string: 'Card must have a DEF of 10', key:'DEF', value:"10"},
        {id:'noAbility', label: 'No Ability', string: 'Card must NOT have a card ability', key:'ability', value:""},
        {id:'byBecca', label: 'Artist: Rebecca Johnston', string: 'Card must be drawn by this artist', key:'artist', value:'Rebecca Johnston'}, 
        {id:'byDylan', label: 'Artist: Dylan Froese', string: 'Card must be drawn by this artist', key:'artist', value:'Dylan Froese'},
        {id:'bySatia', label: 'Artist: Satia Solodky', string: 'Card must be drawn by this artist', key:'artist', value:'Satia Solodky'},
        {id:'byHunter', label: 'Artist: Jordan Hunter', string: 'Card must be drawn by this artist', key:'artist', value:'Jordan Hunter'},
        {id:'byLaundry', label: 'Artist: Jordan Landry', string: 'Card must be drawn by this artist', key:'artist', value:'Jordan Landry'},
        {id:'bySmitty', label: 'Artist: Kyle Smith', string: 'Card must be drawn by this artist', key:'artist', value:'Kyle Smith'},
        {id:'bySchnerch', label: 'Artist: Kyle Schnerch', string: 'Card must be drawn by this artist', key:'artist', value:'Kyle Schnerch'},
        {id:'byBrendan', label: 'Artist: Brendan Miller-Young', string: 'Card must be drawn by this artist', key:'artist', value:'Brendan Miller-Young'},
        {id:'byDerek', label: 'Artist: Derek Miller-Young', string: 'Card must be drawn by this artist', key:'artist', value:'Derek Miller-Young'},
    ];

    const handleColumnFilters = (index, filter) => {
        setLoading(true);
        setColumnFilters(prevFilters => {
            const newFilters = [...prevFilters]; // Create a copy of the existing array
            newFilters[index] = filter; // Update the specific index
            return newFilters; // Return the updated array
        });
        setLoading(false);
    }
    
    const handleRowFilters = (index, filter) => {
        setLoading(true);
        setRowFilters(prevFilters => {
            const newFilters = [...prevFilters]; // Create a copy of the existing array
            newFilters[index] = filter; // Update the specific index
            return newFilters; // Return the updated array
        });
        setLoading(false);
    }

    const validateFilters = (row,column) => {
        if(!props.editing) return true
        if(rowFilters[row].label === '' || columnFilters[column].label === ''){
            return false;
        }
        //validate the filters by checking if rows and column filters mean that cell can have a card
        let valid = false;
        //check if the cells corresponding to the row and column filters can be filled
        let rowFilter = rowFilters[row];
        let columnFilter = columnFilters[column];
        cards.filter((card) => card[rowFilter.key] === rowFilter.value && card[columnFilter.key] === columnFilter.value).length > 0 ? valid = true : valid = false; 
        return valid;
    }

    const validateSelection = (row,column,cell) => {

        //validate the filters by checking if rows and column filters mean that cell can have a card
        let valid = false;
        //check if the cells corresponding to the row and column filters can be filled
        let rowFilter = rowFilters[row];
        let columnFilter = columnFilters[column];
        
        //check if selectedCell can be filled
        cards.filter((card) => card[rowFilter.key] === rowFilter.value && card[columnFilter.key] === columnFilter.value && card.name === cell.value.name).length > 0 ? valid = true : valid = false;
        setEnergy(prevEnergy => prevEnergy - 1);
        return valid;
    }

    const attemptAutoGenerate = (attempt = 0, maxAttempts = 20, usedFilters = new Set()) => {
        // Update state with new filters
        setColumnFilters(
            [{ id: '', label: '' }, { id: '', label: '' }, { id: '', label: '' }]
        );
        setRowFilters(
            [{ id: '', label: '' }, { id: '', label: '' }, { id: '', label: '' }]
        );
    
        if (attempt >= maxAttempts) {
            console.error("Exceeded maximum attempts to generate valid filters.");
            return;
        }
    
        // Generate a random filter for each row and column
        let newColumnFilters = [];
        let newRowFilters = [];
        let rngFilters = filters.slice().filter(filter => !usedFilters.has(filter)); // Filter out used filters
        rngFilters = rngFilters.sort(() => Math.random() - 0.5); // Shuffle remaining filters
    
        for (let i = 0; i < 3; i++) {
            newColumnFilters.push(rngFilters.pop()); // Use the last filter from the shuffled list
            newRowFilters.push(rngFilters.pop()); // Use the last filter from the shuffled list
        }
    
        // Update state with new filters
        setColumnFilters(newColumnFilters);
        setRowFilters(newRowFilters);
    
        // Validate each cell in the 3x3 grid
        let isValid = true;
        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 3; j++) {
                console.log('validating row ' + i + ' and column ' + j + '...')
                console.log('Row filter:', newRowFilters[i].label);
                console.log('Column filter:', newColumnFilters[j].label);
    
                if (!validateFilters(i, j)) {
                    console.log('INVALID');
                    isValid = false;
                    usedFilters.delete(newRowFilters[i]); // Remove the used filter from the set
                    usedFilters.delete(newColumnFilters[j]); // Remove the used filter from the set
                    break; // Break out of the loop if one cell is invalid
                } else {
                    console.log('VALID');
                }
            }
            if (!isValid) {
                break; // Break out of the outer loop if one cell is invalid
            }
        }
    
        // If the filters are not valid, retry
        if (!isValid) {
            attemptAutoGenerate(attempt + 1, maxAttempts, usedFilters);
        }
    }
    
    // Helper function to reattempt generating filters for a specific cell
    const attemptAutoGenerateCell = (rowIndex, colIndex, attempt, maxAttempts) => {
        if (attempt >= maxAttempts) {
            console.error("Exceeded maximum attempts to generate valid filters for the cell.");
            return;
        }
    
        // Generate a random filter for the specified cell
        let rngFilters = filters.slice().sort(() => Math.random() - 0.5);
        let newFilter = rngFilters[rowIndex + colIndex + 3];
    
        // Update state with new filter for the specified cell
        setRowFilters(prevRowFilters => {
            const newRowFilters = [...prevRowFilters];
            newRowFilters[rowIndex] = newFilter;
            return newRowFilters;
        });
    
        setColumnFilters(prevColumnFilters => {
            const newColumnFilters = [...prevColumnFilters];
            newColumnFilters[colIndex] = newFilter;
            return newColumnFilters;
        });
    
        // Validate the new filter for the specified cell
        console.log('Validating row ' + rowIndex + ' and column ' + colIndex + '...');
        console.log('Row filter:', newFilter.label);
        console.log('Column filter:', newFilter.label);
    
        if (!validateFilters(rowIndex, colIndex)) {
            console.log('INVALID');
            // Retry generating filters for the specific cell
            attemptAutoGenerateCell(rowIndex, colIndex, attempt + 1, maxAttempts);
        } else {
            console.log('VALID');
        }
    }
    
    
    

    const saveDoku = () => {
        setLoading(true)
        //save to firestore dokus collection
        firestore.collection('dokus').add({
            columnFilters: columnFilters,
            rowFilters: rowFilters,
            date: new Date(),
        }).then(() => {
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }





    let DokuButton = (stuff) => {
        if(stuff.position === 'topLeft'){
            return <Button onClick={()=> handleOpen('topLeft',0,0)} disabled={!_.isEmpty(cells[0].value) || props.editing || energy === 0}
            startIcon={<React.Fragment>

                <Img  src={cells[0].value.cardImage}
            style={{height:'100%', width:'100%', transform: 'translate(-50%, -50%)',left: '50%',top: '50%',position: 'absolute',}}
             alt={cells[0].value.name}/>
             
             <div style={{backgroundColor:'black', borderRadius:'10px',
                position: 'absolute', bottom: '0', left: '0', right: '0', width: '100%', textAlign: 'center', color: 'white', fontSize: '0.75rem'}}>
                <small>{cells[0].value.name}</small>
            </div>
             </React.Fragment>}
            variant="contained" disableElevation 
            style={{ overflow: 'hidden',width:'64px',height:'64px',backgroundColor:validateFilters(0,0)?'#36a1df':'red', margin:'1px',padding:'64px', borderTopLeftRadius:'15px', borderTopRightRadius:'0px', borderBottomRightRadius:'0px', borderBottomLeftRadius:'0px'}}></Button>
        }
        if(stuff.position === 'topMiddle'){
            return <Button onClick={()=> handleOpen('topMiddle',0,1)} disabled={!_.isEmpty(cells[1].value) || props.editing || energy === 0}
            startIcon={<React.Fragment>

                <Img  src={cells[1].value.cardImage}
            style={{height:'100%', width:'100%', transform: 'translate(-50%, -50%)',left: '50%',top: '50%',position: 'absolute',}}
             alt={cells[1].value.name}/>
             
             <div style={{backgroundColor:'black', borderRadius:'10px',
                position: 'absolute', bottom: '0', left: '0', right: '0', width: '100%', textAlign: 'center', color: 'white', fontSize: '0.75rem'}}>
                <small>{cells[1].value.name}</small>
            </div>
             </React.Fragment>}
            variant="contained" disableElevation style={{ overflow: 'hidden',width:'64px',height:'64px',backgroundColor:validateFilters(0,1)?'#36a1df':'red', margin:'1px',padding:'64px', borderRadius:'0px'}}></Button>
        }
        if(stuff.position === 'default'){
            return <Button 
            variant="contained" disableElevation style={{ overflow: 'hidden',width:'64px',height:'64px',backgroundColor:'#36a1df', margin:'1px',padding:'64px', borderRadius:'0px'}}></Button>
        }
        if(stuff.position === 'topRight'){
            return <Button onClick={()=> handleOpen('topRight',0,2)} disabled={!_.isEmpty(cells[2].value) || props.editing || energy === 0}
            startIcon={<React.Fragment>

                <Img  src={cells[2].value.cardImage}
            style={{height:'100%', width:'100%', transform: 'translate(-50%, -50%)',left: '50%',top: '50%',position: 'absolute',}}
             alt={cells[2].value.name}/>
             
             <div style={{backgroundColor:'black', borderRadius:'10px',
                position: 'absolute', bottom: '0', left: '0', right: '0', width: '100%', textAlign: 'center', color: 'white', fontSize: '0.75rem'}}>
                <small>{cells[2].value.name}</small>
            </div>
             </React.Fragment>}
            variant="contained" disableElevation style={{ overflow: 'hidden',width:'64px',height:'64px',backgroundColor:validateFilters(0,2)?'#36a1df':'red', margin:'1px',padding:'64px', borderTopRightRadius:'15px', borderTopLeftRadius:'0px', borderBottomRightRadius:'0px', borderBottomLeftRadius:'0px'}}></Button>
        }
        if(stuff.position === 'leftMiddle'){
            return <Button onClick={()=> handleOpen('leftMiddle',1,0)} disabled={!_.isEmpty(cells[3].value) || props.editing || energy === 0}
            startIcon={<React.Fragment>

                <Img  src={cells[3].value.cardImage}
            style={{height:'100%', width:'100%', transform: 'translate(-50%, -50%)',left: '50%',top: '50%',position: 'absolute',}}
             alt={cells[3].value.name}/>
             
             <div style={{backgroundColor:'black', borderRadius:'10px',
                position: 'absolute', bottom: '0', left: '0', right: '0', width: '100%', textAlign: 'center', color: 'white', fontSize: '0.75rem'}}>
                <small>{cells[3].value.name}</small>
            </div>
             </React.Fragment>}
            variant="contained" disableElevation style={{ overflow: 'hidden',width:'64px',height:'64px',backgroundColor:validateFilters(1,0)?'#36a1df':'red', margin:'1px',padding:'64px', borderRadius:'0px'}}></Button>
        }
        if(stuff.position === 'middleMiddle'){
            return <Button onClick={()=> handleOpen('middleMiddle',1,1)} disabled={!_.isEmpty(cells[4].value) || props.editing || energy === 0}
            startIcon={<React.Fragment>

                <Img  src={cells[4].value.cardImage}
            style={{height:'100%', width:'100%', transform: 'translate(-50%, -50%)',left: '50%',top: '50%',position: 'absolute',}}
             alt={cells[4].value.name}/>
             
             <div style={{backgroundColor:'black', borderRadius:'10px',
                position: 'absolute', bottom: '0', left: '0', right: '0', width: '100%', textAlign: 'center', color: 'white', fontSize: '0.75rem'}}>
                <small>{cells[4].value.name}</small>
            </div>
             </React.Fragment>}
            variant="contained" disableElevation style={{ overflow: 'hidden',width:'64px',height:'64px',backgroundColor:validateFilters(1,1)?'#36a1df':'red', margin:'1px',padding:'64px', borderRadius:'0px'}}></Button>
        }
        if(stuff.position === 'rightMiddle'){
            return <Button onClick={()=> handleOpen('rightMiddle',1,2)} disabled={!_.isEmpty(cells[5].value) || props.editing || energy === 0}
            startIcon={<React.Fragment>

                <Img  src={cells[5].value.cardImage}
            style={{height:'100%', width:'100%', transform: 'translate(-50%, -50%)',left: '50%',top: '50%',position: 'absolute',}}
             alt={cells[5].value.name}/>
             
             <div style={{backgroundColor:'black', borderRadius:'10px',
                position: 'absolute', bottom: '0', left: '0', right: '0', width: '100%', textAlign: 'center', color: 'white', fontSize: '0.75rem'}}>
                <small>{cells[5].value.name}</small>
            </div>
             </React.Fragment>}
            variant="contained" disableElevation style={{ overflow: 'hidden',width:'64px',height:'64px',backgroundColor:validateFilters(1,2)?'#36a1df':'red', margin:'1px',padding:'64px', borderRadius:'0px'}}></Button>
        }
        if(stuff.position === 'bottomLeft'){
            return <Button 
            onClick={()=> handleOpen('bottomLeft',2,0)} disabled={!_.isEmpty(cells[6].value) || props.editing || energy === 0}
            startIcon={<React.Fragment>

                <Img  src={cells[6].value.cardImage}
            style={{height:'100%', width:'100%', transform: 'translate(-50%, -50%)',left: '50%',top: '50%',position: 'absolute',}}
             alt={cells[6].value.name}/>
             
             <div style={{backgroundColor:'black', borderRadius:'10px',
                position: 'absolute', bottom: '0', left: '0', right: '0', width: '100%', textAlign: 'center', color: 'white', fontSize: '0.75rem'}}>
                <small>{cells[6].value.name}</small>
            </div>
             </React.Fragment>}
            variant="contained" disableElevation style={{ overflow: 'hidden',width:'64px',height:'64px',backgroundColor:validateFilters(2,0)?'#36a1df':'red', margin:'1px',padding:'64px', borderBottomLeftRadius:'15px', borderTopRightRadius:'0px', borderBottomRightRadius:'0px', borderTopLeftRadius:'0px'}}></Button>
        }
        if(stuff.position === 'bottomMiddle'){
            return <Button onClick={()=> handleOpen('bottomMiddle',2,1)} disabled={!_.isEmpty(cells[7].value) || props.editing || energy === 0}
            startIcon={<React.Fragment>

                <Img  src={cells[7].value.cardImage}
            style={{height:'100%', width:'100%', transform: 'translate(-50%, -50%)',left: '50%',top: '50%',position: 'absolute',}}
             alt={cells[7].value.name}/>
             
             <div style={{backgroundColor:'black', borderRadius:'10px',
                position: 'absolute', bottom: '0', left: '0', right: '0', width: '100%', textAlign: 'center', color: 'white', fontSize: '0.75rem'}}>
                <small>{cells[7].value.name}</small>
            </div>
             </React.Fragment>}
            variant="contained" disableElevation style={{ overflow: 'hidden',width:'64px',height:'64px',backgroundColor:validateFilters(2,1)?'#36a1df':'red', margin:'1px',padding:'64px', borderRadius:'0px'}}></Button>
        }
        if(stuff.position === 'bottomRight'){
            return <Button onClick={()=> handleOpen('bottomRight',2,2)} disabled={!_.isEmpty(cells[8].value) || props.editing || energy === 0}
            startIcon={<React.Fragment>

                <Img  src={cells[8].value.cardImage}
            style={{height:'100%', width:'100%', transform: 'translate(-50%, -50%)',left: '50%',top: '50%',position: 'absolute',}}
             alt={cells[8].value.name}/>
             
             <div style={{backgroundColor:'black', borderRadius:'10px',
                position: 'absolute', bottom: '0', left: '0', right: '0', width: '100%', textAlign: 'center', color: 'white', fontSize: '0.75rem'}}>
                <small>{cells[8].value.name}</small>
            </div>
             </React.Fragment>}
            variant="contained" disableElevation style={{ overflow: 'hidden',width:'64px',height:'64px',backgroundColor:validateFilters(2,2)?'#36a1df':'red', margin:'1px',padding:'64px', borderBottomRightRadius:'15px', borderTopLeftRadius:'0px', borderTopRightRadius:'0px', borderBottomLeftRadius:'0px'}}></Button>
        }

    }

    const handleSelect = (card) => {
       
        setOpen(false);
        setSelectedCell(
            {...selectedCell, value: card}
        )
        let newCells = cells
        //find the index of the selected cell
        let index = newCells.findIndex((cell) => cell.id === selectedCell)
        newCells[index].value = card
            
        if(validateSelection(selectedRow,selectedColumn,newCells[index])){
            setCells(newCells)
            return
        } else {
            console.log('Invalid Selection')
            setSelectedCell(
                {...selectedCell, value: {}}
            )
            newCells[index].value = {}
            setCells(newCells)
            return
        }
    
    }

    {console.log(columnFilters)}
    return (
        <Grid container >
            
            <Modal
                open={open}
                onClose={handleClose}
                style={{display:'flex',alignItems:'center',justifyContent:'center'}}
            >
                <Paper style={{backgroundColor:'#36a1df', padding:'20px', minWidth:'75%',maxWidth:'75%'}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Make your guess
                    </Typography>
                    {columnFilters[selectedColumn].id !== '' ? <Alert style={{margin:'10px'}} variant="outlined" severity="warning">{columnFilters[selectedColumn].string}</Alert>:null}
                    {rowFilters[selectedRow].id !== '' ? <Alert style={{margin:'10px'}}  variant="outlined" severity="warning">{rowFilters[selectedRow].string}</Alert>:null}
                    <TextField fullWidth label="Search cards..." variant="outlined" onChange={(e)=>setSearchText(
                        e.target.value
                    )}/>
                    <Grid container style={{maxHeight:'500px',maxWidth:'100%',overflowY:'scroll'}}>
                      
                        {searchText.length >= 3 && cards.filter(
                            (card) => card.name.toLowerCase().includes(searchText.toLowerCase()))
                            //filter out any cards that are duplicates and foil is true

                        .map((card, index) => {
                            return (
                                <Grid item xs={12} key={index}>
                                    <Button style={{color:'black'}} onClick={()=> handleSelect(card)} startIcon={
                                        <Img placeholder={Sleeve} src={card.cardImage} style={{height:'64px', width:'64px'}} alt={card.name}/>
                                    }>
                                        {card.freenian ? card.name + ' (Freenian)' :card.name}
                                        </Button>
                                    <Divider/>
                                </Grid>
                            )
                        }
                        )}
                     
                    </Grid>

                </Paper>
            </Modal>
            
            <Grid container spacing={1} alignItems="flex-end" style={{marginBottom:'15px'}}>
                <Grid item xs={12}>
                    {dokuData.length > 0 ?<Typography variant="h4" style={{textAlign:'center'}}>{
                        //convert seconds to moment formatted date
                        moment(new Date(
                            dokuData.date.seconds * 1000 + dokuData.date.nanoseconds / 1000000,
                          )).format('MMMM Do YYYY')
                    }</Typography>:null}
                    <Divider/>
                </Grid>
                <Grid item xs={4} />
                {!props.editing ? columnFilters.map((filter, index) => (
                    <Grid item xs={1.4} key={index}>
                        <div style={{ maxWidth: '100px', backgroundColor: '#1f1e1e', borderRadius: '10px', fontSize: '1rem' }}>
                            {filter.label.includes('Artist: ') ? <BrushIcon style={{color: 'white'}}/>:null}
                            <small>{filter.label.replace('Artist:','')}</small>
                            
                        </div>
                    </Grid>
                )):null}

                {props.editing &&
                <><Grid item xs={1.4}>
                        <FormControl fullWidth>
                            <InputLabel>Column 1</InputLabel>
                            <Select
                                value={columnFilters[0]}

                                label="Column 1"
                                onChange={(e) => handleColumnFilters(0, e.target.value)}
                            >
                                {filters.map((filter, index) => (
                                    <MenuItem key={index} value={filter}>{filter.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid><Grid item xs={1.4}>
                            <FormControl fullWidth>
                                <InputLabel>Column 2</InputLabel>
                                <Select
                                    value={columnFilters[1]}
                                    onChange={(e) => handleColumnFilters(1, e.target.value)}
                                >
                                    {filters.map((filter, index) => (
                                        <MenuItem key={index} value={filter}>{filter.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1.4}>
                            <FormControl fullWidth>
                                <InputLabel>Column 3</InputLabel>
                                <Select
                                    value={columnFilters[2]}
                                    onChange={(e) => handleColumnFilters(2, e.target.value)}
                                >
                                    {filters.map((filter, index) => (
                                        <MenuItem key={index} value={filter}>{filter.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        
                        </>
                }
                
            </Grid>
            <Grid container spacing={1}>
                {/* Row 1 */}
                <Grid item xs={4}>
                    {/* Row Label 1 */}
                    {!props.editing && rowFilters.length > 0 ?
                        <div style={{ maxWidth: '100px', backgroundColor: '#1f1e1e', borderRadius: '10px', 
                            position: 'relative', left: '70%', top:'25%', width: '100%', textAlign: 'center', color: 'white', fontSize: '1rem'
                        }}>
                            {rowFilters[0].label.includes('Artist: ') ? <BrushIcon style={{color: 'white'}}/>:null}
                            <small>{rowFilters[0].label.replace('Artist:','')}</small>
                        </div>
                    :null}
                            {props.editing &&
                    
                    <div style={{ maxWidth: '200px', 
                        position: 'relative', left: '40%', top:'25%', width: '100%', textAlign: 'center', color: 'white', fontSize: '1rem'
                    }}>
                        <FormControl fullWidth>
                            <InputLabel>Row 1</InputLabel>
                            <Select
                                value={rowFilters[0]}
                                onChange={(e) => handleRowFilters(0, e.target.value)}
                            >
                                {filters.map((filter, index) => (
                                    <MenuItem key={index} value={filter}>{filter.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                }
                </Grid>
                <Grid item xs={8} container spacing={1}>
                    {/* Cells */}
                    {DokuButton({position:'topLeft'})}
                    {DokuButton({position:'topMiddle'})}
                    {DokuButton({position:'topRight'})}
                </Grid>
                {/* Row 2 */}
                <Grid item xs={4}>
                    {/* Row Label 2 */}
                    {!props.editing && rowFilters.length > 1 ?
                        <div style={{ maxWidth: '100px', backgroundColor: '#1f1e1e', borderRadius: '10px', 
                            position: 'relative', left: '70%', top:'25%', width: '100%', textAlign: 'center', color: 'white', fontSize: '1rem'
                        }}>
                            {rowFilters[1].label.includes('Artist: ') ? <BrushIcon style={{color: 'white'}}/>:null}
                            <small>{rowFilters[1].label.replace('Artist:','')}</small>
                        </div>
                    :null}
                    {props.editing &&
                    
                        <div style={{ maxWidth: '200px', 
                            position: 'relative', left: '40%', top:'25%', width: '100%', textAlign: 'center', color: 'white', fontSize: '1rem'
                        }}>
                            <FormControl fullWidth>
                                <InputLabel>Row 2</InputLabel>
                                <Select
                                    value={rowFilters[1]}
                                    onChange={(e) => handleRowFilters(1, e.target.value)}
                                >
                                    {filters.map((filter, index) => (
                                        <MenuItem key={index} value={filter}>{filter.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    }
                </Grid>
                <Grid item xs={8} container spacing={1}>
                    {/* Cells */}
                    {DokuButton({position:'leftMiddle'})}
                    {DokuButton({position:'middleMiddle'})}
                    {DokuButton({position:'rightMiddle'})}
                </Grid>
                {/* Row 3 */}
                <Grid item xs={4}>
                    {/* Row Label 3 */}
                    {!props.editing && rowFilters.length > 2 ?
                        <div style={{ maxWidth: '100px', backgroundColor: '#1f1e1e', borderRadius: '10px',
                            position: 'relative', left: '70%', top:'25%', width: '100%', textAlign: 'center', color: 'white', fontSize: '1rem'
                        }}>
                            {rowFilters[2].label.includes('Artist: ') ? <BrushIcon style={{color: 'white'}}/>:null}
                            <small>{rowFilters[2].label.replace('Artist:','')}</small>
                        </div>
                    :null}
                            {props.editing &&
                    
                    <div style={{ maxWidth: '200px', 
                        position: 'relative', left: '40%', top:'25%', width: '100%', textAlign: 'center', color: 'white', fontSize: '1rem'
                    }}>
                        <FormControl fullWidth>
                            <InputLabel>Row 3</InputLabel>
                            <Select
                                value={rowFilters[2]}
                                onChange={(e) => handleRowFilters(2, e.target.value)}
                            >
                                {filters.map((filter, index) => (
                                    <MenuItem key={index} value={filter}>{filter.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                }
                </Grid>
                <Grid item xs={8} container spacing={1}>
                    {/* Cells */}
                    {DokuButton({position:'bottomLeft'})}
                    {DokuButton({position:'bottomMiddle'})}
                    {DokuButton({position:'bottomRight'})}
                    <Typography variant="h6" style={{textAlign:'center', marginTop:'40px', marginLeft:'20px'}}>Energy: {energy}/10</Typography>
                </Grid>
                <Grid item xs={12}>
                    {props.editing && <Button style={{marginRight:'10px'}} color="secondary" variant="contained" onClick={()=> attemptAutoGenerate()}>Auto Generate</Button>}

                    {props.editing &&<Button variant="contained"
                        onClick={()=> saveDoku()}
                        disabled={
                            loading || cells.filter((cell) => !validateFilters(0,0) || !validateFilters(0,1) || !validateFilters(0,2) || !validateFilters(1,0) || !validateFilters(1,1) || !validateFilters(1,2) || !validateFilters(2,0) || !validateFilters(2,1) || !validateFilters(2,2)).length > 0
                         }
                    >Submit Doku</Button>}
                    <Divider style={{marginBottom:'20px'}}/>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DokuGrid;