import React from "react";
import {  Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

const FranchiseCard = (props) => {

    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width:600px)');
    //useEffect for media query
    React.useEffect(() => {

    } , [matches])



  return (
    <div >

        <Card sx={matches ? { display: 'flex' } : { display: 'flex' ,cursor:'pointer',  justifyContent: "flex-end"}} onClick={() => {
                                navigate('/franchises/franchise?name='+props.franchiseData.name, { state: { franchise: props.franchiseData} });
              
                            }}>
             <Box sx={matches ? { display: 'flex', flexDirection: 'row' } : { display: 'flex', flexDirection: 'row' }}>
                <CardContent  sx={{ flex: '1 0 auto' }}>
                    <Typography variant={matches ? 'body1' : 'h5'} style={matches?{fontSize:'12px'}:{}}>{props.franchiseData.name}</Typography>
                    <Typography variant={matches ? 'body1' : 'h5'} style={{fontSize:'12px'}}>{props.characters} Characters</Typography>
                </CardContent>
             </Box>
            <CardMedia 
                image={props.franchiseData.image}
                title={props.franchiseData.name}
                style={matches?{height:'60px', width:'60px', marginLeft:'10%', marginTop:'20%',flexShrink: 0} :{height:'96px', width:'120px', flexShrink: 0}}
            /> 
            
        </Card>
    </div>
  );
};

export default FranchiseCard;