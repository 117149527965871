import * as React from 'react';
import { firestore } from '../../firebase/firebase';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
//import { useLocation } from 'react-router-dom';
import { Divider, Typography, TextField, Button, Checkbox } from '@mui/material';
import { FormControl, MenuItem, Select, Input } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import _ from 'lodash';

const GalaxyCard = (props) => {

    //const location = useLocation()
    const [loading, setLoading] = React.useState(true);
    const [logoData , setLogoData] = React.useState(sessionStorage.getItem('logos') ? JSON.parse(sessionStorage.getItem('logos')):[]);
    const [originData, setOriginData] = React.useState(sessionStorage.getItem('expansions') ? JSON.parse(sessionStorage.getItem('expansions')):[]);
    const [galaxyData, setgalaxyData] = React.useState(
        sessionStorage.getItem('galaxyData') ? JSON.parse(sessionStorage.getItem('galaxyData')) : props.galaxyData);
    //let path = new URLSearchParams(location.search).get("docId");

    const matches = useMediaQuery('(max-width:600px)');

    //handle setgalaxyData
    const handleSetgalaxyData = (e, value) => {
        var newData = galaxyData
        newData[e.target.name] = value
        setgalaxyData({
            ...galaxyData,
            [e.target.name]: value
        })
        sessionStorage.setItem('galaxyData', JSON.stringify(newData));
        props.updateData(newData);
    }

    //useEffect for media query
    React.useEffect(() => {
  
    } , [matches])

    React.useEffect(() => {
        setLoading(true);
        let response = [];
        let logoResponse = [];
        if(!sessionStorage.getItem('logos')){
        firestore.collection('logos').orderBy('name', 'asc').get()
        .then(querySnapshot => {
                logoResponse = querySnapshot.docs.map((doc) => {
                    return {
                    ...doc.data()
                    }

                })

                setLogoData(logoResponse);
                setLoading(false);
            }).catch (e => {
                
                setLoading(false);
            });
        }else {
            setLoading(false);
        }
            if(!sessionStorage.getItem('expansions')){
        firestore.collection('expansions').orderBy('name', 'asc').get()
            .then(querySnapshot => {
                response = querySnapshot.docs.map((doc) => {
                        return {
                        ...doc.data()
                        }
    
                    })
    
                    setOriginData(response);
                    setLoading(false);
                }).catch (e => {
                    
                    setLoading(false);
                });
            }

            

    } , [])

    const handleSubmit = (e) => {
        setLoading(true);
        
        props.saveData()
    }

    return (
    
    <React.Fragment>
        <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            '#075d8e',
          margin: "10%",
          overflow: 'hidden',
        }}
        >
        <Container  sx={{ p: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Paper elevation={2} style={{padding:'5px', height:'100%'}}>
                    <Typography align="center" variant='body1' style={{lineHeight:'2'}}> <b>Upload Card Image: </b> </Typography>
                    <Divider />
                        <Input
                            id="uploadImage"
                            type='file'
                            accept="image/*"
                            name="cardImage"
                            disabled={props.saving && loading}
                            onChange={(e) => { handleSetgalaxyData(e, e.target.files[0])}}
                            required/>
                    {galaxyData.cardImage !== '' ? <img style={{width: '350px'}}
                    src={!_.isUndefined(galaxyData.cardImage) && galaxyData.cardImage instanceof File ? URL.createObjectURL(galaxyData.cardImage) : galaxyData.cardImage} 
                    alt="Uploaded"  /> : null}
                    </Paper>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Paper elevation={2} style={{padding:'5px'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Card Name: </b> </Typography>
                            </Grid>

                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                <TextField 
                                    value={galaxyData.name}
                                    onChange={(e) => { handleSetgalaxyData(e, e.target.value) }}
                                    fullWidth
                                    disabled={props.saving}
                                    required
                                    name="name"
                                    size="small"
                                    variant="outlined"
                                    label="Name"
                                
                                />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>ATK/DEF: </b> </Typography>
                            </Grid>

                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                <TextField 
                                    value={galaxyData.ATK}
                                    onChange={(e) => { handleSetgalaxyData(e, e.target.value)}}
                                    fullWidth
                                    size="small"
                                    disabled={props.saving}
                                    //type='number'
                                    name="ATK"
                                    required
                                    variant="outlined"
                                    label="ATK"
                                
                                />
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                <TextField 
                                    value={galaxyData.DEF}
                                    onChange={(e) => { handleSetgalaxyData(e, e.target.value)}}
                                    fullWidth
                                    disabled={props.saving}
                                    //type='number'
                                    name="DEF"
                                    required
                                    size="small"
                                    variant="outlined"
                                    label="DEF"
                                
                                />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Foil/Holo?: </b> </Typography>
                            </Grid>

                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                <Checkbox style={{bottom:'7px'}}
                                    checked={galaxyData.foil}
                                    onChange={(e) => { handleSetgalaxyData(e, e.target.checked)}}
                                    disabled={props.saving}
                                    name="foil"
                                    size="small"

                                />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Materials Needed: </b> </Typography>
                            </Grid>

                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                <TextField 
                                    value={galaxyData.materialCount}
                                    onChange={(e) => { handleSetgalaxyData(e, e.target.value) }}
                                    fullWidth
                                    disabled={props.saving}
                                    required
                                    name="materialCount"
                                    size="small"
                                    variant="outlined"
                                    type='number'
                                    label="Materials Needed"
                                
                                />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Material Franchise: </b> </Typography>
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="Franchise"
                                    value={galaxyData.Franchise}
                                    label="Franchise"
                                    variant='outlined'
                                    required
                                    disabled={props.saving}
                                    onChange={(e) => { handleSetgalaxyData(e, e.target.value)}}
                                >
                                    {logoData.map((logo) => {
                                        return (
                                            <MenuItem key={logo.name} value={logo.name}><img src={logo.image} style={{width:'48px'}} alt={logo.name+ ' image'}/>{logo.name}</MenuItem>
                                        )
                                    })}
                                    
                                    
                                </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Ability: </b> </Typography>
                            </Grid>

                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                <TextField 
                                    value={galaxyData.ability}
                                    onChange={(e) => { handleSetgalaxyData(e, e.target.value)}}
                                    fullWidth
                                    name="ability"
                                    size="small"
                                    multiline
                                    disabled={props.saving}
                                    rows={5}
                                    placeholder='Enter Ability...'
                                    variant="outlined"
                                    
                                
                                />
                            </Grid>

                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Artist: </b> </Typography>
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                <TextField 
                                    value={galaxyData.artist}
                                    onChange={(e) => { handleSetgalaxyData(e, e.target.value)}}
                                    fullWidth
                                    name="artist"
                                    size="small"
                                    disabled={props.saving}
                                    required
                                    variant="outlined"
                                    label="Artist"
                                
                                />
                            </Grid>
                            
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Typography align="left" variant='body1' style={{lineHeight:'2'}}> <b>Origin: </b> </Typography>
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={galaxyData.origin}
                                    label="Origin"
                                    name="origin"
                                    variant='outlined'
                                    disabled={props.saving}
                                    required
                                    onChange={(e) => { handleSetgalaxyData(e, e.target.value)}}
                                ><MenuItem value="">None</MenuItem>
                                    {originData.map((logo) => {
                                        return (
                                            <MenuItem key={logo.name} value={logo.name}>
                                                {logo.boxImage ? <img src={logo.boxImage} style={{width:'48px'}} alt={logo.name+ ' image'}/> : <HelpOutlineIcon/>}
                                                &nbsp;{logo.name} {logo.year ? '('+logo.year+')' : null}</MenuItem>
                                        )
                                    })}
                                    
                                    
                                </Select>
                                </FormControl>
                            </Grid>
                            
                            
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Button variant="filled" onClick={() => handleSubmit()} disabled={props.saving}
            style={{backgroundColor:'white', color:'black'}}>
                {props.saving ? 'SUBMITTING...' : 'Submit Galaxy'}
            </Button>

        
        </Container>
      </Box>    
    </React.Fragment>
    );
}
export default GalaxyCard;