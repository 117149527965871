import React from 'react';

function PDFViewer(props) {
  const { pdfUrl } = props;

  return (
    <iframe
      src={pdfUrl}
      title="PDF Viewer"
      width="100%"
      height="800px"
    />
  );
}

export default PDFViewer;