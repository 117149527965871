import React, { useEffect, useState} from 'react';
import {Box, Button, IconButton, Popover } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate  } from "react-router-dom";
//import { DataGrid } from '@mui/x-data-grid';
import Img from 'react-cool-img';
import Sleeve from '../img/Sleeve.png';
//import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useCards } from '../providers/cardProvider';
import { Typography, TextField, Checkbox, FormControlLabel, Grid } from '@mui/material';
import bleed from '../img/bleed.png';
import activate from '../img/equiptypes/activate.png'
import equip from '../img/equiptypes/equip.png'
import continuous from '../img/equiptypes/continuous.png'
import ritual2 from '../img/equiptypes/ritual.png'
import quickplay from '../img/equiptypes/quickplay.png'
import Sparkles from "../components/Sparkles";
import useMediaQuery from '@mui/material/useMediaQuery';



const CharacterTable = (props) => {
    const matches = useMediaQuery('(max-width:1000px)');
    const [loading, setLoading] = useState(true);
    const { character, equipment, locations, virus, ritual, sacrifice, god, galaxy, logos } = useCards();
    const [searchValue, setSearchValue] = useState(JSON.parse(sessionStorage.getItem('search')) ? JSON.parse(sessionStorage.getItem('search')) : '');
    const [foil, setFoil] = useState( false);
    const [equipType, setEquipType] = useState(JSON.parse(sessionStorage.getItem('equipType')) ? JSON.parse(sessionStorage.getItem('equipType')) : '');
    const [virusType, setVirusType] = useState(JSON.parse(sessionStorage.getItem('virusType')) ? JSON.parse(sessionStorage.getItem('virusType')) : '');
    const [logo, setLogo] = useState(JSON.parse(sessionStorage.getItem('logo')) ? JSON.parse(sessionStorage.getItem('logo')) : '');
    const [filteredData, setFilteredData] = useState(JSON.parse(sessionStorage.getItem('searchData')) ? JSON.parse(sessionStorage.getItem('searchData')) : [])
    //push history to move to different page
    React.useEffect(() => {
  
    } , [matches])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openPopoverIndex, setOpenPopoverIndex] = useState(null);

    const handlePopoverOpen = (event, index) => {
      setOpenPopoverIndex(index);
      setAnchorEl(event.currentTarget);
    };
    
    const handlePopoverClose = () => {
      setOpenPopoverIndex(null);
      setAnchorEl(null);
    };
  
    //const open = Boolean(anchorEl);
    const navigate = useNavigate();
    function getCardList(cardType) {
      switch(cardType) {
        case 'character': return character;
        case 'equipment': return equipment;
        case 'locations': return locations;
        case 'virus': return virus;
        case 'ritual': return ritual;
        case 'sacrifice': return sacrifice;
        case 'god': return god;
        case 'galaxy': return galaxy;
        default: return [];
      }
    }

   /* function getColumns(cardType) {
      switch(cardType)
      {case 'character': return characterColumns;
      case 'equipment': return equipmentColumns;
      case 'location': return locationColumns;
      case 'virus': return virusColumns;
      case 'ritual': return ritualColumns;
      case 'sacrifice': return sacrificeColumns;
      case 'god': return godColumns;
      case 'galaxy': return galaxyColumns;
      default: return characterColumns;}
    }*/

    //get index of card in list us
    const getCardIndex = (id, name) => {
      let cardList = []
      cardList = getCardList(props.listType).filter(card=> card.name.toLowerCase()
      .replace(/\s/g, '-')
      .replace(/#/g, '')
      .replace(/[.:'"]/g, '') === name)
      let index = cardList.findIndex((item) => {
        return item.id === id;
      })

      return index;
    }


    const goToCharacter = (id, name) => {
        navigate(`/cards/${props.cardSet}/${name}-${id}`, { state: { id: name},
      });
    }

    useEffect(() => {
      if(!sessionStorage.getItem(props.cardType)){
        setLoading(true);
        let response = [];
        response = getCardList(props.listType)
        

          sessionStorage.setItem(props.cardType, JSON.stringify(response));
          setLoading(false);
        
          } else {
            setLoading(false);
          }
    } , [])
    useEffect(() => {
      let newData = JSON.parse(sessionStorage.getItem(props.cardType));
      let filtered = newData;
    
      if (searchValue !== '') {
        filtered = filtered.filter((item) => {
          return item.name.toLowerCase().includes(searchValue.toLowerCase());
        });
      }
    
      if (foil) {
        filtered = filtered.filter((item) => {
          return item.foil === foil || item.cloudyfoil === foil;
        });
      }
    
      if (props.cardType === 'equipments' && equipType !== '') {
        filtered = filtered.filter((item) => {
          return item.eType === equipType;
        });
      }
    
      if (props.cardType === 'viruses' && virusType !== '') {
        filtered = filtered.filter((item) => {
          return item.vType === virusType;
        });
      }
    
      if (props.cardType === 'characters' && logo !== '') {
        filtered = filtered.filter((item) => {
          return item.Franchise === logo;
        });
      }
    
      setFilteredData(filtered);
      sessionStorage.setItem('searchData', JSON.stringify(filtered));
      sessionStorage.setItem('virusType', JSON.stringify(virusType));
      sessionStorage.setItem('equipType', JSON.stringify(equipType));
    }, [searchValue, foil, props.cardType, equipType, virusType, logo]);

    const equipmentTypes =[
      'Activate',
      'Equip',
      'Ritual',
      'Continuous',
      'Quickplay'
    ]

    const virusTypes =[
      'Φ',
      'Σ',
      'Ω',
    ]

    function getEquipTypeIcon (type) {
      switch(type) {
        case 'Activate': return activate;
        case 'Equip': return equip;
        case 'Ritual': return ritual2;
        case 'Continuous': return continuous;
        case 'Quickplay': return quickplay;
        default: return activate;
      }
    }

  return (<React.Fragment>
{loading ? <Box sx={{ height: 600, width: '100%' }}><Skeleton variant="rectangular" sx={{ bgcolor: '#00324f' }}  animation="wave" width={1200} height={400} /></Box> :
    <Box sx={{ height: '100%', width: '100%' }}>
      <Grid container>
        <Grid item xs={6} md={6}>
          <Typography variant={matches?'h6':'h4'} sx={{marginBottom:'10px'}}>{props.cardType.charAt(0).toUpperCase() + props.cardType.slice(1)}</Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography variant={matches?'h6':'h4'} sx={{marginBottom:'10px'}}>Total: {filteredData.length}</Typography>
        </Grid>
      </Grid>
      <Grid container style={{justifyContent:'center', marginBottom:'16px'}}>
          {props.cardType === 'equipments' ? 
              <React.Fragment>
          {equipmentTypes.map((type, index) => {
            return (
              <Grid item xs={2} key={index}>
                <Button onClick={() => equipType === type ? setEquipType('') : setEquipType(type) }variant='contained' style={equipType === type ? {border:'1px solid #00d632', color: '#00d632',backgroundColor:'black', margin:'0px'}:{backgroundColor:'black', margin:'0px'}} 
                startIcon={matches?null:<Img src={getEquipTypeIcon(type)} alt={type} style={{width:'24px', margin:'0px'}} />}
                >{matches?<Img src={getEquipTypeIcon(type)} alt={type} style={{width:'24px', margin:'0px'}} />:type}</Button>
              </Grid>
            )
          })} 
              <Grid item xs={2}>
                <Button onClick={() => setEquipType('')}variant='contained' style={equipType === '' ? {border:'1px solid #00d632', color: '#00d632',backgroundColor:'black', margin:'0px'}:{backgroundColor:'black', margin:'0px'}}>All</Button>
              </Grid>
        </React.Fragment> :null}
      {props.cardType === 'viruses' ? 
      <React.Fragment>
          {virusTypes.map((type, index) => {
            return (
              <Grid item xs={2} key={index}>
                <Button onClick={() => virusType === type ? setVirusType('') : setVirusType(type) } variant='contained' style={virusType === type ? {border:'1px solid #5319ff', color: '#5319ff',backgroundColor:'black', margin:'0px'}:{backgroundColor:'black', margin:'0px'}}>
                  {type}{matches?null:' Virus'}</Button>
              </Grid>
            )
          })} 
          
          <Grid item xs={2} >
            <Button onClick={() => setVirusType('') }variant='contained' style={virusType === '' ? {border:'1px solid #5319ff', color: '#5319ff',backgroundColor:'black', margin:'0px'}:{backgroundColor:'black', margin:'0px'}}>All</Button>
          </Grid>
          

        </React.Fragment> :null}

    {props.cardType === 'characters' ? 
    <React.Fragment>
      {logos.map((franchise, index) => (
        <Grid item xs={1} key={index} style={{marginBottom:'4px'}}>
          <IconButton
            onMouseEnter={(event) => handlePopoverOpen(event, index)}
            onMouseLeave={handlePopoverClose}
            onClick={logo !== franchise.name ? () => {
              setLogo(franchise.name);
              sessionStorage.setItem('logo', JSON.stringify(franchise.name));
              
            }: () => { setLogo('');
            sessionStorage.setItem('logo', JSON.stringify(''));}}
            variant='contained'
            style={
              logo === franchise.name
                ? { border: '2px solid #ff1100', backgroundColor: 'black', margin: '0px', width:!matches?'64px':'32px', height:!matches?'64px':'32px', overflow: 'hidden'  }
                : { border: '1px solid white',backgroundColor: 'black', margin: '0px', width:!matches?'64px':'32px', height:!matches?'64px':'32px', overflow: 'hidden' }
            }
          >
            <img
              src={franchise.image}
              alt={franchise.name}
              style={{
                maxWidth: matches?'64px':'96px',  // Add this line to limit the image size
                height: 'auto',    // Maintain aspect ratio
              }}
            />
          </IconButton>
          <Popover
            id={`mouse-over-popover-${index}`} // Use a unique ID for each Popover
            sx={{ pointerEvents: 'none' }}
            open={openPopoverIndex === index}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 1 }}>{franchise.name}</Typography>
          </Popover>
        </Grid>
      ))}
      <Grid item xs={1}>
      <IconButton
        onClick={() => {
          setLogo('');
          sessionStorage.setItem('logo', JSON.stringify(''));
        }
          
        }
        variant='contained'
        style={
          logo === ''
            ? { border: '2px solid #ff1100', backgroundColor: 'black', margin: '0px', width:!matches?'64px':'32px', height:!matches?'64px':'32px', overflow: 'hidden'  }
            : { border: '1px solid white',backgroundColor: 'black', margin: '0px', width:!matches?'64px':'32px', height:!matches?'64px':'32px', overflow: 'hidden' }
        }
      >
        <Typography style={{color:'white'}}>ALL</Typography>
      </IconButton>
    </Grid>
      
    </React.Fragment> : null}

      </Grid>
      
      <Grid container>
        
        <Grid item xs={12} md={6}>
          <TextField 
            variant='outlined'
            fullWidth
            label='Search by Card Name'
            value={searchValue}
            sx={{marginBottom:'10px', backgroundColor:'lightGray', borderRadius:'10px'}}
            onChange={(e) => {setSearchValue(e.target.value); sessionStorage.setItem('search', JSON.stringify(e.target.value))}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel control={<Checkbox   
              checked={foil} color="secondary"
              onChange={(e) => {setFoil(e.target.checked); sessionStorage.setItem('foilFilter', JSON.stringify(e.target.value))}}
            />} label="Show Foil Only" />
        </Grid>
      </Grid>
      {/*<DataGrid style={{backgroundColor:'white',cursor:'pointer'}}
        rows={filteredData}
        columns={getColumns(props.listType)}
        pageSize={10}
        rowsPerPageOptions={[5,10,20,50]}
        disableSelectionOnClick
        
        onRowClick={(e, row) => { goToCharacter(getCardIndex(e.id, e.row.name.toLowerCase().replace(/\s/g, '-').replace(/[.:']/g, '').replace(/#/g, '')), e.row.name.toLowerCase().replace(/\s/g, '-').replace(/#/g, '').replace(/[.:']/g, ''))}}
          />*/}

      <Grid container spacing={1} style={{}}>
        {filteredData.map((card, index) => {
          return (
            <Grid item xs={2} key={index} style={matches?{marginRight:'27px'}:{}}>
              {card.foil || card.cloudyfoil ?
                <Sparkles><Img placeholder={Sleeve} src={card.cardImage} alt={card.name} style={{width:matches?'120px':'200px', cursor:'pointer',
                            maskImage: `url(${bleed})`,
                            maskSize:  matches?'120px auto':'200px auto',
                            maskRepeat: 'no-repeat',
                            maskPosition: 'center',
                            WebkitMaskImage: `url(${bleed})`,
                            WebkitMaskSize:  matches?'120px auto':'200px auto',
            }} 
            onClick={() => {goToCharacter(
              getCardIndex(card.id, card.name.toLowerCase()
                .replace(/\s/g, '-')
                .replace(/#/g, '')
                .replace(/[.:'"]/g, '')),  // Added the double quote replacement here
              card.name.toLowerCase()
                .replace(/\s/g, '-')
                .replace(/#/g, '')
                .replace(/[.:'"]/g, ''))}}></Img></Sparkles>
              :

              <Img placeholder={Sleeve} src={card.cardImage} alt={card.name} style={{width:matches?'120px':'200px', cursor:'pointer',
                            maskImage: `url(${bleed})`,
                            maskSize: matches?'120px auto':'200px auto',
                            maskRepeat: 'no-repeat',
                            maskPosition: 'center',
                            WebkitMaskImage: `url(${bleed})`,
                            WebkitMaskSize:  matches?'120px auto':'200px auto',
            }} 
            onClick={() => {goToCharacter(
              getCardIndex(card.id, card.name.toLowerCase()
                .replace(/\s/g, '-')
                .replace(/#/g, '')
                .replace(/[.:'"]/g, '')),  // Added the double quote replacement here
              card.name.toLowerCase()
                .replace(/\s/g, '-')
                .replace(/#/g, '')
                .replace(/[.:'"]/g, ''))}}></Img>
          }
            </Grid>
          )

        })
        }
      </Grid>

      </Box>
    }</React.Fragment>
  );
} 
export default CharacterTable;