import React from 'react';
import Img from 'react-cool-img';
import Sleeve from '../../img/Sleeve.png';
import Sparkles from '../Sparkles';
import FoilCard from '../foil/FoilCard';
import NormalCard from '../foil/NormalCard';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Input } from '@mui/material';
import bleed from '../../img/bleed.png';
//import './basic.css'; 

const CardImage = (props) => { 
  const matches = useMediaQuery('(max-width:600px)');


  return !props.edit ? (
    props.cardData.foil || props.cardData.cloudyfoil ? (
      <Sparkles>
        <FoilCard
          image={props.cardData.cardImage}
          style={{
            maskImage: `url(${bleed})`,
            maskSize: '400px auto',
            maskRepeat: 'no-repeat',
            maskPosition: 'center',
            WebkitMaskImage: `url(${bleed})`,
            WebkitMaskSize: '400px auto',
          }}
        />
      </Sparkles>
    ) : (
      <NormalCard
      image={props.cardData.cardImage}
      backImage={Sleeve}
      style={{
        maskImage: `url(${bleed})`,
        maskSize: '400px auto',
        maskRepeat: 'no-repeat',
        maskPosition: 'center',
        WebkitMaskImage: `url(${bleed})`,
        WebkitMaskSize: '400px auto',
        
      }}
    />
    )
  ) : (
    <React.Fragment>
      <Input
        id="uploadImage"
        type="file"
        accept="image/*"
        name="cardImage"
        onChange={(e) => {
          props.replaceImage(e);
        }}
        required
      />

      <Img
        placeholder={Sleeve}
        src={props.cardData.cardImage}
        alt={props.cardData.name}
        style={matches ? { width: '300px' } : { width: '400px', borderRadius: '10px' }}
      />
    </React.Fragment>
  );
};

export default CardImage;
