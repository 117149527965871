
import React, { useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Img from 'react-cool-img'
import Sleeve from '../img/Sleeve.png';
import LOGO from '../img/LogoNew.png';
import Marquee from "react-fast-marquee";
import { Typography, Slide } from '@mui/material';
import { useCards } from '../providers/cardProvider';
import { useNavigate } from "react-router-dom";
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import bleed from '../img/bleed.png';
import FirstSleeve from '../img/FirstSleeve.png';
import SecondSleeve from '../img/SecondSleeve.png';
import FirstMicke from '../img/FirstMicke.png';
import SecondMicke from '../img/SecondMicke.png';
import ThirdMicke from '../img/ThirdMicke.png';
import useMediaQuery from '@mui/material/useMediaQuery';



function DashboardContent() {
  const matches = useMediaQuery('(max-width:1000px)');
  const { totalCount, upcomingCount, character, equipment, sacrifice, galaxy, god, ritual, locations, virus, cardsLoading} = useCards();
  const [randomCard, setRandomCard] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
  
  } , [matches])


  useEffect(() => {
    if(!cardsLoading) {
      let list = [...character, ...equipment, ...sacrifice, ...galaxy, ...god, ...ritual, ...locations];
      if(!loading && list.length > 0) {
       
        setTimeout(() => {
          setRandomCard(list[Math.floor(Math.random() * list.length)]);
          setLoading(true)
          setTimeout(() => {
            setLoading(false)
          }, 5000)
        }, 1000)
        
      }
    }
    
    
  }, [cardsLoading, loading]);


  function getCardList(cardType) {
    switch(cardType) {
      case 'character': return character;
      case 'equipment': return equipment;
      case 'location': return locations;
      case 'virus': return virus;
      case 'ritual': return ritual;
      case 'sacrifice': return sacrifice;
      case 'god': return god;
      case 'galaxy': return galaxy;
      default: return [];
    }
  }

  const getCardIndex = (id, name, type) => {
    let cardList = []
    cardList = getCardList(type).filter(card=> card.name.toLowerCase().replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, '') === name)
    let index = cardList.findIndex((item) => {
      return item.id === id;
    })

    return index;
  }

  const goToCharacter = (id, name, type) => {
    navigate(`/cards/${type}/${name}-${id}`, { state: { id: name},
  });
}

  return (
    
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              '#075d8e',
            flexGrow: 0,
            width: '100%',
            marginTop:matches?"30%":"10%",
            maxWidth:'100%',
            overflowX:'hidden',
          }}
        >
   <Marquee gradientColor={[0,0,0]} speed={100}>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                </Marquee>
                  
                  
                  
                <Marquee gradientColor={[0,0,0]} speed={100} direction={'right'} style={{marginTop:'5px'}}>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                  <Img placeholder={Sleeve} src={Sleeve} alt="" style={{width:'50px', marginRight:'2px'}}/>
                </Marquee>
                <Grid container spacing={1} direction="row" alignItems="center" justifyContent="center" >
                  <Slide direction={loading ? "right": "left" } in={loading} mountOnEnter unmountOnExit timeout={loading ? 3000 : 500} 
                  easing={
                    'ease-in-out' 
                  }>
                      <img onClick={() => goToCharacter(getCardIndex(randomCard.id, randomCard.name.toLowerCase().replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, ''), randomCard.type), randomCard.name.toLowerCase().replace(/#/g, '').replace(/\s/g, '-').replace(/[.:']/g, ''), randomCard.type)}
                      src={randomCard.cardImage} alt="randomCard" style={{width:matches?'150px':'232px', backgroundColor:'#36a1df', borderRadius:'10px', marginTop:matches?'5px':'15px', marginLeft:'100px',
                                      maskImage: `url(${bleed})`,
                                      maskSize: matches?'150px auto':'232px auto',
                                      maskRepeat: 'no-repeat',
                                      maskPosition: 'center',
                                      WebkitMaskImage: `url(${bleed})`,
                                      WebkitMaskSize: matches?'150px auto':'232px auto',
                                      cursor:'pointer'
                    }}/>
                    </Slide>
                    <img src={LOGO} alt="randomCard2" style={{width:matches?'150px':'232px', backgroundColor:'#36a1df', borderRadius:'10px', marginTop:matches?'5px':'15px', marginLeft:'-100px', opacity:'0%'}}/>
                </Grid>
          <Container  sx={{ p:4 }}>
                  
            <Grid container spacing={2} style={{marginBottom:'10px'}}>
              <Grid item xs={12} md={12} lg={12} sx={{}} >
                  
              </Grid>
              <Grid item xs={6} md={6} lg={6}  >
                <Typography variant={matches?'h6':'h4'}>Total Cards: {totalCount}</Typography>
              </Grid>
              <Grid item xs={6} md={6} lg={6}  >
                <Typography variant={matches?'h6':'h4'}>Upcoming: {upcomingCount}</Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}  >
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    KFK Battalion is a card game designed by Derek Miller-Young and printed by The Game Crafter. 
                </Paper>
              </Grid>
            </Grid>

            <Grid container style={{flexAlign:'center', display:'flex'}}>
              <Grid item xs={4} md={4} lg={4}  >
                <Flippy
                  flipOnHover={true} // default false
                  flipDirection="horizontal" // horizontal or vertical
                >
                  <FrontSide style={{ boxShadow: '0 0px 0px 0 rgba(0,0,0,0)'}} >
                    <img src={FirstMicke} alt="firstMicke" style={{width:matches?'130px':'200px', borderRadius:'10px', transformStyle: 'preserve-3d',
                                  maskImage: `url(${bleed})`,
                                  maskSize: matches?'130px auto':'200px auto',
                                  maskRepeat: 'no-repeat',
                                  maskPosition: 'center',
                                  WebkitMaskImage: `url(${bleed})`,
                                  WebkitMaskSize: matches?'130px auto':'200px auto', }}/>
              
                  </FrontSide>
                  <BackSide style={{ boxShadow: '0 0px 0px 0 rgba(0,0,0,0)'}}>
                    <img src={FirstSleeve} alt="FirstSleeve" style={{width:matches?'130px':'200px', borderRadius:'10px', transformStyle: 'preserve-3d',
                                  maskImage: `url(${bleed})`,
                                  maskSize: matches?'130px auto':'200px auto',
                                  maskRepeat: 'no-repeat',
                                  maskPosition: 'center',
                                  WebkitMaskImage: `url(${bleed})`,
                                  WebkitMaskSize: matches?'130px auto':'200px auto',}}/>
                    
                  </BackSide>
                </Flippy>   
                <Typography variant={'h6'}>1st Edition</Typography> 
              </Grid>
              <Grid item xs={4} md={4} lg={4}  >
                <Flippy
                  flipOnHover={true} // default false
                  flipDirection="horizontal" // horizontal or vertical
                >
                  <FrontSide style={{ boxShadow: '0 0px 0px 0 rgba(0,0,0,0)'}} >
                    <img src={SecondMicke} alt="firstMicke" style={{width:matches?'130px':'200px', borderRadius:'10px', transformStyle: 'preserve-3d',
                                  maskImage: `url(${bleed})`,
                                  maskSize: matches?'130px auto':'200px auto',
                                  maskRepeat: 'no-repeat',
                                  maskPosition: 'center',
                                  WebkitMaskImage: `url(${bleed})`,
                                  WebkitMaskSize: matches?'130px auto':'200px auto',}}/>
                    
                  </FrontSide>
                  <BackSide style={{ boxShadow: '0 0px 0px 0 rgba(0,0,0,0)'}}>
                    <img src={SecondSleeve} alt="FirstSleeve" style={{width:matches?'130px':'200px', borderRadius:'10px', transformStyle: 'preserve-3d',
                  maskImage: `url(${bleed})`,
                  maskSize: matches?'130px auto':'200px auto',
                  maskRepeat: 'no-repeat',
                  maskPosition: 'center',
                  WebkitMaskImage: `url(${bleed})`,
                  WebkitMaskSize: matches?'130px auto':'200px auto',}}/>
                
                  </BackSide>
                </Flippy>    
                <Typography variant={'h6'}>2nd Edition</Typography>
              </Grid> 
              <Grid item xs={4} md={4} lg={4}  >
                <Flippy
                  flipOnHover={true} // default false
                  flipDirection="horizontal" // horizontal or vertical
                >
                  <FrontSide style={{ boxShadow: '0 0px 0px 0 rgba(0,0,0,0)'}} >
                    <img src={ThirdMicke} alt="firstMicke" style={{width:matches?'130px':'200px', borderRadius:'10px', transformStyle: 'preserve-3d',
                  maskImage: `url(${bleed})`,
                  maskSize: matches?'130px auto':'200px auto',
                  maskRepeat: 'no-repeat',
                  maskPosition: 'center',
                  WebkitMaskImage: `url(${bleed})`,
                  WebkitMaskSize: matches?'130px auto':'200px auto',}}/>
                    
                  </FrontSide>
                  <BackSide style={{ boxShadow: '0 0px 0px 0 rgba(0,0,0,0)'}}>
                    <img src={Sleeve} alt="FirstSleeve" style={{width:matches?'130px':'200px', borderRadius:'10px', transformStyle: 'preserve-3d',
                  maskImage: `url(${bleed})`,
                  maskSize: matches?'130px auto':'200px auto',
                  maskRepeat: 'no-repeat',
                  maskPosition: 'center',
                  WebkitMaskImage: `url(${bleed})`,
                  WebkitMaskSize: matches?'130px auto':'200px auto',}}/>
                    
                  </BackSide>
                </Flippy>    
                <Typography variant={'h6'}>3rd Edition</Typography>
              </Grid> 
            </Grid>



            <Grid container>
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Typography variant='body1'>Beginning in 2010, KFK Battalion was designed to be a fun, easy to learn, and intense card game following the basic traditions
                    of the card game genre. <br></br>
                    Friends from all walks of life have contributed to the game's development and have helped to create a fantastical and wacky experience. <br></br>
                    From drawing new cards, suggesting mechanics or changes, to just playing the game, everyone has helped to make KFK Battalion what it is today. <br></br>

                    <br></br>

                    The name KFK Battalion came from a combination of two eras, the late 90s and early 2000s when Derek & his close friends would draw comics on whatever
                    paper they could find and sign them all under <b>Komix For Kidz.</b><br></br> This was heavily inspired by the popular kids book at the time: Captain Underpants.<br></br> 
                    <br></br>
                    The Battalion part came from the later 2000s during Derek's time playing & making games on BYOND, usually titling the games with something something Battalion. <br></br>

                    <br></br><br></br>
                    Now this website has been created to be a database for all the cards in the game, as well a place to keep track of neat facts and information<br></br>
                     such as the various expansions, booster packs and rulesets. <br></br>

                    </Typography>
                </Paper>
              </Grid>
              
   
              <Grid item xs={12}>
                
              </Grid>
            </Grid>
            
          </Container>
        </Box>

  );
}

export default function Dashboard() {
  return <DashboardContent />;
}