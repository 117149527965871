import React from 'react';
import './App.css';
import Dashboard from './containers/Home';
import Card from './containers/Card';
import { Route, Routes  } from "react-router-dom";
import Link from '@mui/material/Link';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
//import LOGO from './img/Sleeve.png';
import { IconButton, Grid } from '@mui/material';
import ICON from './img/icon.png';
import { useNavigate  } from "react-router-dom";
import NavBar from './components/navbar';
import Login from './containers/Login';
import AdminBar from './components/adminBar';
import { useAuth } from './providers/authProvider';
import _ from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';
import CreateCard from './Admin/containers/CreateCard';
import CreateExpansion from './Admin/containers/CreateExpansion';
import Cards from './containers/Cards';
import Expansions from './containers/Expansions';
import Expansion from './containers/Expansion';
import MooseLogo from './img/MooseLogo.png';
import Franchises from './containers/Franchises';
import Franchise from './containers/Franchise';
import Rules from './containers/Rules';
import { useCards } from './providers/cardProvider';
import Meta from './providers/meta';
import Gallery from './containers/Gallery/CommGallery';
import Upcoming from './containers/UpcomingCards';
import { ConfirmProvider } from "material-ui-confirm";
import Page404 from './containers/Page404';
import Artist from './containers/Artist';
import Doku from './containers/Doku';

function Copyright(props) {
  return (
    <React.Fragment><Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mickemoose.ca/">
        Derek Miller-Young
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
    <Typography><img src={MooseLogo} style={{width:'64px', marginTop:'5%'}} alt="Mickemoose Logo"></img></Typography></React.Fragment>
  );
}

const mdTheme = createTheme();
function App() {
  const { currentUser, loading } = useAuth();
  const { cardsLoading } = useCards();
  const matches = useMediaQuery('(max-width:1000px)');

  const navigate = useNavigate();
  //useEffect for media query
  React.useEffect(() => {

  } , [matches])

  
  return (
    <div className="App" >
      <header className="App-header" style={{backgroundColor:'#075d8e'}}>
      <ThemeProvider theme={mdTheme}>
        <CssBaseline />
        
        <Meta  />
        <ConfirmProvider>


        <React.Fragment>
              <AppBar style={{backgroundColor: '#36a1df', zIndex:'1000'}} position="fixed">
                  
                    <Grid container spacing={1} direction="row">
                    {!matches?null:
                     <Grid item xs={2}>
                        <NavBar/>
                      </Grid>
                    }
                      <Grid item xs={matches?8:12}>
                        <IconButton onClick={() => {navigate('/')}}><img src={ICON} style={
                            matches ? {height:'24px', width:'24px' } : {height:'64px', width:'64px' }} alt="HOME ICON"/></IconButton>
                      </Grid>
                     
                    </Grid>
            
                {matches?null:
                <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center" >
                  <NavBar/>
                </Grid>}
              </AppBar>

              {!cardsLoading &&
              
              <Routes>
                <Route exact path="/" element={<Dashboard/>}/>
                <Route exact path="/cards/character/:card" element={<Card cardType={'character'} cardTypes={'characters'} firebaseType={'characters'}/>}/>
                <Route exact path="/cards/character/:card/:foil" element={<Card cardType={'character'} cardTypes={'characters'} firebaseType={'characters'}/>}/>
                <Route exact path="/cards/equipment/:card" element={<Card cardType={'equipment'} cardTypes={'equipments'} firebaseType={'equipment'}/>}/>
                <Route exact path="/cards/sacrifice/:card" element={<Card cardType={'sacrifice'} cardTypes={'sacrifices'} firebaseType={'sacrifice'}/>}/>
                <Route exact path="/cards/location/:card" element={<Card cardType={'location'} cardTypes={'locations'} firebaseType={'location'}/>}/>
                <Route exact path="/cards/virus/:card" element={<Card cardType={'virus'} cardTypes={'viruses'} firebaseType={'virus'}/>}/>
                <Route exact path="/cards/galaxy/:card" element={<Card cardType={'galaxy'} cardTypes={'galaxies'} firebaseType={'galaxy'}/>}/>
                <Route exact path="/cards/god/:card" element={<Card cardType={'god'} cardTypes={'gods'} firebaseType={'god'}/>}/>
                <Route exact path="/cards/ritual/:card" element={<Card cardType={'ritual'} cardTypes={'rituals'} firebaseType={'ritual'}/>}/>

                <Route exact path="/login" element={<Login/>}/>
                <Route exact path="/admin/createCard" element={
                _.isEmpty(currentUser) || currentUser === null ? <Login/> :
                <CreateCard/>}/>
                <Route exact path="/admin/createExpansion" element={
                _.isEmpty(currentUser) || currentUser === null ? <Login/> :
                <CreateExpansion/>}/>

                <Route exact path="/cards" element={<Cards/>}/>
                <Route exact path="/rules" element={<Rules/>}/>
                <Route exact path="/expansions" element={<Expansions/>}/>
                <Route exact path="/franchises" element={<Franchises/>}/>
                <Route exact path="/gallery" element={<Gallery/>}/>
                <Route exact path="/upcoming" element={<Upcoming/>}/>
                <Route exact path="/artists/:artist" element={<Artist/>}/>

                <Route path="/franchises/franchise/" element={<Franchise/>}/>
                <Route path="/expansions/:expansion" element={<Expansion/>}/>
                <Route path="/doku" element={<Doku/>}/>
                <Route path="*" element={<Page404/>}  />
              </Routes>}
              {matches?null:<NavBar/>}
              { !loading && !_.isEmpty(currentUser) && currentUser !== null
          ? <React.Fragment><Typography><small>Logged in as {currentUser.email}</small></Typography>
          <p><AdminBar/></p></React.Fragment>

           : null }
              <Copyright sx={matches ? { fontWeight:'bold', fontSize:'12px', pt: 1, marginBottom:'10px' } : { pt: 4, marginBottom:'10px' }} />
              </React.Fragment>
              </ConfirmProvider>
            
          
      </ThemeProvider>
      </header>
    </div>
  );
}

export default App;



