
import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, } from '@mui/material';
import { firestore } from '../firebase/firebase';
import FranchiseCard from '../components/franchiseCard';
import { useCards } from '../providers/cardProvider';


function DashboardContent() {
    const { character } = useCards();
    const matches = useMediaQuery('(max-width:600px)');
    const [loading, setLoading] = React.useState(true);
    const [expansions, setExpansions] = React.useState(sessionStorage.getItem('logos') ? JSON.parse(sessionStorage.getItem('logos')):[]);
    const [characters, setCharacters] = React.useState(sessionStorage.getItem('characters') ? JSON.parse(sessionStorage.getItem('characters')):[]);
    
    //useEffect for media query
    React.useEffect(() => {
  
    } , [matches])
    React.useEffect(() => {
      if(!sessionStorage.getItem('logos')){
        setLoading(true)
        firestore.collection('logos').orderBy('name', 'asc').get()
        .then(querySnapshot => {
          const response = querySnapshot.docs.map((doc) => {
            return {
              ...doc.data(),
                id: doc.id  
            }
          })
          setExpansions(response)
          sessionStorage.setItem('logos', JSON.stringify(response));
          setLoading(false)
          
        }).catch (e => {
                
                setLoading(false);
            }
        )


      } else {
      setLoading(false)
      }

      if(!sessionStorage.getItem('characters')){
        
          setCharacters(character)
          setLoading(false)
        
      } else {
        setLoading(false)
      }
     
  

  
    } , [])

  return (
    
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              '#075d8e',
            flexGrow: 1,
            width: '100%',
            marginTop:"10%",
          }}
        ><Typography variant="h4" component="h1" gutterBottom>FRANCHISES</Typography>
          <Container maxWidth="lg" sx={{ p: 4 }}>
            <Divider/>
                <Grid container spacing={2} >
                    {!loading && expansions.map((expansion, index) => {

                    return (
                        <Grid item key={index} xs={6} md={4} lg={4} >
                            <FranchiseCard characters={characters.filter(char => char.Franchise === expansion.name && char.origin.length > 0).length} franchiseData={expansion}/>
                                                
                        </Grid>
                    )
                    })}
                </Grid>
          </Container>
        </Box>

  );
}

export default function Expansions() {
  return <DashboardContent />;
}