
import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import RuleBook from '../components/RuleBook';
import { Collapse, Button } from '@mui/material';

import rulebook from '../img/rulebook.pdf'
import PDFViewer from '../components/PDF/PDFViewer';

function DashboardContent() {
  const [rulebookSite, setRulebookSite] = React.useState(true);
  const [rulebookPDF, setRulebookPDF] = React.useState(false);

  React.useEffect(() => {

  }, []);
    
  return (
    
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              '#075d8e',
            flexGrow: 1,
            width: '100%',

            marginTop:"10%",

          }}
        >
          <Container  sx={{ p:4, height:'100%'}}>
            <Grid container spacing={2} style={{marginBottom:'10px'}} >
              <Grid item xs={6}>
                <Button style={!rulebookSite ? {} : {backgroundColor: 'black', color: '#075d8e', border:'1px solid #075d8e'}}
                variant="contained" onClick={() => {setRulebookSite(!rulebookSite); setRulebookPDF(false);}}>View Onsite Rulebook</Button>
                
              </Grid> 
              <Grid item xs={6}>
                <Button style={!rulebookPDF ? {} : {backgroundColor: 'black', color: '#075d8e', border:'1px solid #075d8e'}}
                variant="contained" onClick={() => {setRulebookPDF(!rulebookPDF); setRulebookSite(false);}}>View Rulebook PDF</Button>
              </Grid>  
            </Grid>
            <Collapse in={rulebookSite} timeout='auto' unmountOnExit>
              <Grid container spacing={2} >
                <Grid item xs={12}>
                  <RuleBook />
                  </Grid>
              </Grid>
            </Collapse>
            <Collapse in={rulebookPDF} timeout='auto' unmountOnExit>
              <Grid container spacing={2} >
                <Grid item xs={12}>
                  <PDFViewer pdfUrl={rulebook} />
                  </Grid>
              </Grid>
            </Collapse>
            
          </Container>
        </Box>

  );
}

export default function Rules() {
  return <DashboardContent />;
}